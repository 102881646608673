.actionButtons {
	display: flex;
	flex-direction: row-reverse;
	gap: .5rem;

	button {
		margin-top: .5rem;
		position: relative;
	}
}

.portaltooltip {
	width: fit-content;
	background-color: var(--clr-alabaster-100);
	color: var(--clr-alabaster-900);
	text-align: center;
	padding: .25rem;
	border-radius: .25rem;
	position: absolute;
	z-index: 2000;
	border: solid 1px var(--clr-alabaster-900);
	font-weight: normal;
	pointer-events: none;
	font-size: 0.9rem;	
	min-width: max-content;
	transform: translateX(-50%);
	&.disabled {
		color: var(--clr-alabaster-400);
		border: solid 1px var(--clr-alabaster-400);
	}
}