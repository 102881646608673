@use '../abstracts' as *;
@use 'styles/abstracts/_sizes' as sizes;

button {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: .25em;
	min-height: sizes.$min-button-height;
	padding: .5em 1em;
	border-radius: .25rem;
	border: solid 1px var(--clr-alabaster-500);
	font-size: .9rem;
	font-weight: 500;
	letter-spacing: 0px;
	cursor: pointer;

	background-color: transparent;
	color: var(--clr-walkawa-gray-700);
	transition: background-color ease-out .15s,
				border-color ease-out .15s,
				color ease-out .15s;

	& svg {
		max-width: 1.25rem;
		max-height: 1.25rem;
	}

	&:not(:disabled):not(.loading) {
		&:focus-visible {
			outline: solid 2px var(--clr-primary-500);
			outline-offset: 2px;
		}

		&:hover {
			background-color: var(--clr-alabaster-100);
			border-color: var(--clr-primary-500);
			color: var(--clr-primary-500);
		}

		&:active {
			background-color: var(--clr-primary-100);
			border-color: var(--clr-primary-700);
			color: var(--clr-primary-700);
		}
	}

	&:disabled {
		cursor: not-allowed;
		background-color: var(--clr-alabaster-100);
		color: var(--clr-alabaster-700);
	}
}

button.primary {
	background-color: var(--clr-primary-500);
	color: var(--clr-alabaster-100);
	border-color: transparent;

	&:not(:disabled):not(.loading) {
		&:hover {
			border-color: transparent;
			background-color: var(--clr-primary-600);
			color: var(--clr-alabaster-100);
		}

		&:active {
			border-color: transparent;
			background-color: var(--clr-primary-700);
			color: var(--clr-alabaster-100);
		}
	}

	&:disabled {
		border-color: transparent;
		background-color: var(--clr-alabaster-200);
		color: var(--clr-alabaster-800);
	}
}

button.secondary {
	background-color: transparent;
	border-color: var(--clr-primary-500);
	color: var(--clr-primary-500);

	&:not(:disabled):not(.loading) {
		&:hover {
			border-color: transparent;
			background-color: var(--clr-primary-500);
			color: var(--clr-alabaster-100);
		}
		
		&:active {
			border-color: transparent;
			background-color: var(--clr-primary-700);
			color: var(--clr-alabaster-100);
		}
	}

	&:disabled {
		border-color: transparent;
		background-color: var(--clr-alabaster-200);
		color: var(--clr-alabaster-800);
	}
}

button.tercery {
	background-color: transparent;
	border-color: var(--clr-primary-500);
	border-style: dashed;

	&:not(:disabled):not(.loading) {
		&:hover {
			border-color: transparent;
			background-color: var(--clr-walkawa-gray-500);
			color: var(--clr-alabaster-100);
		}
		
		&:active {
			border-color: transparent;
			background-color: var(--clr-walkawa-gray-700);
			color: var(--clr-alabaster-100);
		}
	}

	&:disabled {
		border-color: transparent;
		background-color: var(--clr-alabaster-200);
		color: var(--clr-alabaster-800);
	}
}

button.danger {
	border-color: transparent;
	background-color: var(--clr-cinnabar-500);
	color: var(--clr-alabaster-100);

	&:not(:disabled):not(.loading) {
		&:hover {
			border-color: transparent;
			background-color: var(--clr-cinnabar-600);
			color: var(--clr-alabaster-100);
		}

		&:active {
			border-color: transparent;
			background-color: var(--clr-cinnabar-700);
			color: var(--clr-alabaster-100);
		}
	}  

	&:disabled {
		border-color: transparent;
		background-color: var(--clr-alabaster-200);
		color: var(--clr-alabaster-800);
	}
}

button.danger-outlined {
	background-color: transparent;
	border-color: var(--clr-cinnabar-500);
	color: var(--clr-cinnabar-500);

	&:not(:disabled):not(.loading) {
		&:hover {
			border-color: transparent;
			background-color: var(--clr-cinnabar-600);
			color: var(--clr-alabaster-100);
		}

		&:active {
			border-color: transparent;
			background-color: var(--clr-cinnabar-700);
			color: var(--clr-alabaster-100);
		}
	}

	&:disabled {
		border-color: transparent;
		background-color: var(--clr-alabaster-200);
		color: var(--clr-alabaster-800);
	}
}

button.subtle {
	border-color: transparent;
	background-color: transparent;
	color: var(--clr-walkawa-gray-600);
	
	&:not(:disabled):not(.loading) {
		&:hover {
			border-color: transparent;
			color: var(--clr-primary-500);
		}
		
		&:active {
			background-color: var(--clr-primary-100);
			color: var(--clr-primary-500);
		}
	}
	
	&:disabled {
		border-color: transparent;
		background-color: transparent;
		color: var(--clr-alabaster-800);
	}

	> svg.loading-icon {
		display: none;
	}
}

button svg.loading-icon {
	width: 0;
	visibility: hidden;
	transition: width .2s ease-in-out;
}

button:not(:disabled).loading {
	cursor: wait;

	svg.loading-icon {
		visibility: visible;
		width: 1.25rem;
		animation: rotation 1.3s linear infinite;

		line:first-child {
			stroke-opacity: .3;
		}
		line:nth-child(8) {
			stroke-opacity: .4;
		}
		line:nth-child(6) {
			stroke-opacity: .5;
		}
		line:nth-child(4) {
			stroke-opacity: .6;
		}
		line:nth-child(2) {
			stroke-opacity: .7;
		}
		line:nth-child(7) {
			stroke-opacity: .8;
		}
		line:nth-child(5) {
			stroke-opacity: .9;
		}
		line:nth-child(3) {
			stroke-opacity: 1;
		}
	}
}

@keyframes rotation {
	to {
		transform: rotate(360deg);
	}
}

button.icon-prefix {
	background-color: transparent;
	border-color: transparent;

	svg.loading-icon {
		display: none;
	}

	&.loading:not(:disabled) {
		svg:not(.loading-icon) {
			display: none;
		}
		
		svg.loading-icon {
			display: block;
		}
	}

	svg {
		stroke: var(--clr-walkawa-gray-600);
	}
	
	&:not(:disabled) {
		&:hover, &:active {
			background-color: var(--clr-alabaster-100);
			border-color: var(--clr-primary-100);
		}

		&:hover svg {
			stroke: var(--clr-primary-500);
		}
		&:active svg {
			stroke: var(--clr-primary-700);
		}
	}

	&:disabled svg{
		stroke: var(--clr-alabaster-500)
	}
}

button.icon-only {
	background-color: transparent;
	border-color: transparent;
	padding: .25rem;
	aspect-ratio: 1;
	border-radius: 50%;

	svg.loading-icon {
		display: none;
	}

	&.loading:not(:disabled) {
		svg:not(.loading-icon) {
			display: none;
		}
		
		svg.loading-icon {
			display: block;
		}
	}

	svg {
		stroke: var(--clr-walkawa-gray-600);

		&.primary {
			stroke: var(--clr-primary-500);
		}

		&.orange {
			stroke: orange;
		}

		&.red {
			stroke: red;
		}

		&.grey {
			stroke: #878F9A;
		}
	}

	&:not(:disabled) {
		&:hover, &:active {
			background-color: var(--clr-alabaster-100);
			border-color: var(--clr-primary-100);
		}

		&:hover svg {
			stroke: var(--clr-primary-500);
		}
		&:hover svg.orange {
			stroke: orange;
		}
		&:hover svg.red {
			stroke: red;
		}
		&:active svg {
			stroke: var(--clr-primary-700);
		}
	}

	&:disabled svg{
		stroke: var(--clr-alabaster-500)
	}
}

button.icon-only-primary {
	background-color: var(--clr-primary-500);
	color: var(--clr-alabaster-100);
	border-color: transparent;
	padding: .25rem;
	aspect-ratio: 1;
	border-radius: 50%;

	svg.loading-icon {
		display: none;
	}

	&.loading:not(:disabled) {
		svg:not(.loading-icon) {
			display: none;
		}
		
		svg.loading-icon {
			display: block;
		}
	}

	svg {
		stroke: var(--clr-alabaster-100);
	}

	&:not(:disabled) {
		&:hover, &:active {
			border-color: transparent;
			background-color: var(--clr-primary-600);
		}

		&:hover svg {
			stroke: var(--clr-alabaster-100);
		}
		&:active svg {
			stroke: var(--clr-alabaster-300);
		}
	}

	&:disabled svg{
		border-color: transparent;
		background-color: var(--clr-alabaster-200);
		stroke: var(--clr-alabaster-800)
	}
}

button[role="tab"] {
	color: var(--clr-walkawa-gray-600);
	font-weight: 400;
	border-bottom: 2px solid transparent;
	border-color: transparent;
	border-radius: 0;

	transition: border-color .2s ease-in-out,
				background-color .2s ease-in-out,
				color .2s ease-in-out;

	&:not(:disabled):not(.loading) {
		&:hover {
			border-color: transparent;
			color: var(--clr-primary-500);
		}
		
		&[aria-selected="true"] {
			border-bottom-color: var(--clr-primary-500);
			color: var(--clr-primary-500);
		}
	}
	
	&:disabled {
		border-color: transparent;
		background-color: transparent;
		color: var(--clr-alabaster-800);
	}
}

button.options {
	background-color: transparent;
	border-color: transparent;
	padding: .25rem;

	svg.loading-icon {
		display: none;
	}

	svg {
		stroke: var(--clr-walkawa-gray-600);
	}

	&:not(:disabled) {
		&:hover, &:active {
			background-color: transparent;
			border-color: transparent;
		}

		&:hover svg {
			stroke: var(--clr-primary-500);
		}
		&:active svg {
			stroke: var(--clr-primary-700);
		}
	}

	&:disabled svg{
		stroke: var(--clr-alabaster-500)
	}
}