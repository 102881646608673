.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1040;
	background-color: rgba(0, 0, 0, 0.5);

	.modal-wrapper {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1050;
		width: 100%;
		height: 100%;
		outline: 0;
		display: flex;
		align-items: center;
	}

	.modal-content {
		z-index: 100;
		background: #fff;
		position: relative;
		margin: auto;
		border-radius: 5px;
		max-width: 80%;
		min-width: 50%;
		max-height: 90%;
		padding: 0 1rem 1rem 1rem;
		overflow-y: auto;

		> form {
			width: 100%;
		}
	}

	.modal-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
