@import 'styles/base/_mixins.scss';

.status-evolution-item {
	position: relative;
	display: grid;
	grid-template-rows: auto auto;
	gap: .25rem;
	justify-content: center;

	list-style: none;

	&:not(:last-child)::after {
		content: '\276F';

		position: absolute;
		right: -1rem;
		top: 0;
		transform: translateY(50%);

		color: var(--clr-alabaster-500);
	}

	
	time {
		font-size: .75rem;
		color: var(--clr-walkawa-gray-500);
	}

	&--skeleton {
		@extend .status-evolution-item;
		span, time {
			@include skeleton-ui;
		}
	}
}

@include skeleton-ui-animation;
