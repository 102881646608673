table.import-form {
	// Inputs inside the import table should not have a label displayed.
	label,
	span.hint,
	span.error-message {
		display: none;
	}

	td {
		.row-actions {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-around;
			gap: .5em;
		}
	}
}

.div-flex {
	display: flex;
	justify-content: space-between;
}
