@use '../abstracts' as *;
@use 'styles/base/_typography' as typography;
@use 'styles/abstracts/_sizes' as sizes;

%editor-section {
	background-color: #fff;
	border-radius: .5em;
	box-shadow: 0px 0px .25em #00000029;
	padding: 1em;
}

@mixin grid(
	$gap: null,
	$columnGap: .5em,
	$rowGap: .5em,
	$templateAreas: null,
	$templateColumns: null,
	$templateRows: null,
	$autoRows: null,
	$autoColumns: null,
	$autoFlow: null,
) {
	display: -ms-grid; // Compatibility for MS Edge
	display: grid;

	@if ($gap) {
		gap: $gap;
	} @else {
		grid-column-gap: $columnGap;
		grid-row-gap: $rowGap;
	}

	@if ($templateAreas) {
		grid-template-areas: $templateAreas;
	}
	@if ($templateColumns) {
		grid-template-columns: $templateColumns;
	}
	@if ($templateRows) {
		grid-template-rows: $templateRows;
	}
	@if ($autoRows) {
		grid-auto-rows: $autoRows;
	}
	@if ($autoColumns) {
		grid-auto-columns: $autoColumns;
	}
	@if ($autoFlow) {
		grid-auto-flow: $autoFlow;
	}
}

.template-editor {
	@include grid($columnGap: 2em, $templateAreas: 'Editor Aside', $templateColumns: minmax(440px, 1fr) 440px);
	height: 100%;
	overflow: hidden;
	padding: 0.1em;

	> * {
		padding: 0.1em;
	}

	aside {
		grid-area: Aside;
		display: flex;
		gap: 1em;
		flex-direction: column;
		overflow: auto;

		.toolbox {
			flex: 1;
		}
	}

	.editor-container {
		@extend %editor-section;
		display: flex;
		position: relative;
		overflow-y: auto;
	}

	.editor {
		@extend %editor-section;
		grid-area: Editor;
		user-select: none;
		position: absolute;
		inset: 0;
		overflow: auto;
		padding: 0;
		width: 100%;

		.element,
		.element * {
			overflow: hidden;
			cursor: grab;
		}
	}

	.viewer {
		width: 100%;
		height: 100%;
	}

	// header.css

	.editor-header {
		@extend %editor-section;
		@include grid($templateAreas: 'Template-information' 'Version' 'Actions');

		.template-information {
			grid-area: Template-information;

			input[name="name"], input[name="description"] {
				padding: .25rem;

				&::placeholder {
					font-style: italic;
				}
			}
		}

		.version {
			grid-area: Version;
		}

		.actions {
			grid-area: Actions;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			align-items: center;
			gap: 1em;
		}
	}

	.editor-footer {
		display: flex;
		position: absolute;
		flex-direction: row;
		align-items: center;
		gap: 1em;
		z-index: 200;
		bottom: 0;
		background-color: var(--clr-alabaster-100);
		margin: 1em;
		padding: 0.25em .5em;
		border-radius: .25em;
		box-shadow: 0px 0px 0.25em rgba(0, 0, 0, 0.1607843137);
		right: 0;

		> p {
			margin: 0;
		}

		.editor-footer-zoom {
			input {
				background-color: #fff;
				min-height: 1em;
				width: 3.25em;

				&:read-only {
					background-color: var(--clr-alabaster-100);
				}
			}

			.editor-footer-zoom-select__menu {
				min-width: 240px;
			}
		}
	}

	// format.css
	.format {

		&.expanded {
			grid-row-gap: .5em;
		}

		.header {
			@include grid($templateColumns: 1fr auto);
			grid-area: Header;
			align-items: center;
			user-select: none;
			cursor: pointer;

			.title {
				color: var(--clr-walkawa-gray-900);
				font-size: 1.125rem;
			}

			.details {
				font-size: sizes.$short-paragraph-font-size;
				font-weight: 600;
			}
		}

		.tab-options-list {
			display: flex;
			flex-direction: column;
			gap: .5em;
			margin-top: .5em;
		}

		@extend %editor-section;
		justify-items: center;

		div[role="tablist"] {
			grid-area: Tabs;
			@include grid($columnGap: .5em, $autoFlow: column);

			width: 100%;
			justify-items: center;
			align-items: center;
		}

		div[role="tabpanel"] {
			grid-area: Content;
			width: 100%;
		}
	}

	// entityRow.css & entitiesPanel.css

	.entities {
		@extend %editor-section;
		@include grid($rowGap: 0, $templateAreas: 'Header' 'Content' 'Actions');
		align-items: center;
		transition: all ease-out .3s;

		&.expanded {
			grid-row-gap: .5em;
		}

		.header {
			@include grid($templateColumns: 1fr auto);
			grid-area: Header;
			align-items: center;
			user-select: none;
			cursor: pointer;

			.title {
				color: var(--clr-walkawa-gray-900);
				font-size: 1.125rem;
			}

			.details {
				font-size: sizes.$short-paragraph-font-size;
				font-weight: 600;
			}
		}

		.actions {
			grid-area: Actions;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
		}

		.content {
			grid-area: Content;

			.no-entity-message {
				@extend .short;
				margin: 0;
			}

			.entity-row {
				@include grid($columnGap: .75em, $templateAreas: 'Icon Info Options' 'Details Details Details', $templateColumns: auto 1fr auto);
				position: relative;
				padding: .5em;
				align-items: center;
				border: 1px solid transparent;
				border-radius: .25em;
				transition: padding .2s ease-in-out,
							border-color .2s ease-in-out;

				.info {
					@include grid($gap: .25em, $templateAreas: 'Title Issued-indicator' 'Description Description', $templateColumns: auto 1fr);

					.title, .description, .issued-indicator {
						@extend .short;
						align-self: center;
						margin: 0;
						line-height: 1em;
					}
					.title {
						grid-area: Title;
						font-weight: 600;
					}

					.description {
						grid-area: Description;
						color: var(--clr-walkawa-gray-400);
					}

					.issued-indicator {
						grid-area: Issued-indicator;

						& > span {
							@extend .caption;
							text-transform: capitalize;
							background-color: var(--clr-alabaster-200);
							color: var(--clr-walkawa-gray-600);
							border-radius: 1rem;
							padding: .2rem .5rem;
							cursor: help;
						}
					}
				}

				&.expanded {
					padding: .75em;
					border-color: #4767D6;
				}

				.icon {
					grid-area: Icon;
					width: 2.25em;
					height: 2.25em;
					padding: .25em;
					background-color: var(--clr-alabaster-100);
					border-radius: .25em;

					svg {
						display: block;
						aspect-ratio: 1;
						height: 100%;
						margin: auto;
					}
				}

				.options {
					grid-area: Options;
					position: relative;

					.dropdown {
						position: absolute;
					}
				}

				.entity-form:not([hidden]) {
					grid-area: Details;
					display: flex;
					flex-direction: column;
					gap: 1em;
					max-width: 100%;

					input,
					textarea,
					.react-select > [class$="control"] {
						background-color: #fff;
					}

					.actions {
						@include grid($gap: .5em, $templateColumns: 1fr auto);
						justify-items: end;
					}

					.entity-issue {
						@include grid($gap: .5em, $templateColumns: auto 1fr);
						align-items: center;
					}
				}
			}
		}
	}

	// toolbox.css

	.toolbox {
		@extend %editor-section;
		@include grid($rowGap: .75em, $templateAreas: 'Header' 'Tabs' 'Content', $autoRows: auto auto 1fr);
		justify-items: center;

		.header {
			grid-area: Header;
			@include grid($gap: 1em, $templateAreas: 'Bar Arrow', $templateColumns: 1fr auto);
			align-items: center;
			width: 100%;

			&.properties {
				grid-template-areas: 'Arrow Bar';
				grid-template-columns: auto 1fr;
			}

			.bar {
				grid-area: Bar;
			}

			.arrow-button, .close-button {
				grid-area: Arrow;
				border: 1px solid var(--clr-walkawa-gray-300);
				border-radius: .25em;
				width: 50px;
				aspect-ratio: 1;

				&:hover {
					background-color: var(--clr-alabaster-100);
				}
			}

			.element-properties {
				border: solid 1px var(--clr-walkawa-gray-300);
				padding-left: 10px;
				border-radius: .25em;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				grid-column-gap: .5em;

				.icon {
					width: 1.75em;
					height: 1.75em;
					background-color: var(--clr-alabaster-100);
					border: solid 1px transparent;
					border-radius: .25em;

					svg {
						display: block;
						height: 100%;
						margin: auto;
					}
				}

				.name {
					@include typography.format-paragraph('short');
					color: var(--clr-walkawa-gray-400);
				}
			}
		}

		div[role="tablist"] {
			grid-area: Tabs;
			@include grid($columnGap: .5em, $autoFlow: column);

			width: 100%;
			justify-items: center;
			align-items: center;
		}

		div[role="tabpanel"] {
			grid-area: Content;
			width: 100%;
		}

		.capabilities {
			@include grid($rowGap: 1em);

			input, textarea {
				min-height: auto;
			}

			.options-capability {
				.info-wrapper {
					display: flex;
					align-items: center;

					& > *:first-child {
						flex: 1;
					}

					& > *:last-child {
						margin-left: 12px;
						cursor: help;
					}
				}
			}
		}

		button.danger-outlined {
			margin: 1.5em auto auto auto;
		}
	}

	.drag-layer {
		position: fixed;
		pointer-events: none;
		z-index: 100;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	.drop-here {
		font-size: 14px;
		z-index: 100;
		font-weight: 600;
		color: var(--clr-walkawa-gray-500);
		user-select: none;
		min-height: 5em;
		border-radius: .5em;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		background-color: var(--clr-alabaster-100);
		border: 2px dashed var(--clr-walkawa-gray-500);
		transition: color .2s ease-in-out,
					background-color .2s ease-in-out,
					border-color .2s ease-in-out;

		&.drag-over {
			color: var(--clr-eucalyptus-500);
			background-color: var(--clr-shamrock-100);
			border-color: var(--clr-eucalyptus-500);
		}

		&.can-drop:not(.drag-over) {
			background-color: var(--clr-primary-100);
			border-color: var(--clr-primary-500);
			color: var(--clr-primary-500);
		}
	}

	.page {
		margin: 1em;
		display: flex;
		flex-direction: column;
		outline: 1px solid var(--clr-walkawa-gray-300);
		background-color: #fff;
	}

	.page-header {
		position: relative;

		width: 100%;
		min-height: 1em;

		flex-shrink: 0;
		flex-grow: 0;

		overflow: hidden;

		background-color: transparent;
		border-bottom: 1px solid var(--clr-walkawa-gray-100);

		&:focus {
			overflow: visible;
			background-color: #fff;
			z-index: 999;
		}

		&:focus-within {
			overflow: visible;
			background-color: #fff;
			z-index: 999;
		}
	}

	.page-content {
		flex: 1;
		flex-shrink: 0;
	}

	.page-footer {
		position: relative;

		margin-top: auto;

		min-height: 1em;
		width: 100%;

		flex-shrink: 0;
		flex-grow: 0;

		resize: none;
		overflow: hidden;

		background-color: transparent;
		border-top: 1px solid var(--clr-walkawa-gray-100);

		&:focus {
			overflow: visible;
			background-color: #fff;
			z-index: 999;
		}

		&:focus-within {
			overflow: visible;
			background-color: #fff;
			z-index: 999;
		}
	}

	.element {
		color: inherit;
		border-radius: .25em;
		border: 0;
		box-shadow: none;
		height: fit-content;
		max-width: 100%;
		max-height: max-content;

		> * {
			text-justify: auto;
			white-space: pre-line;
		}

		display: flex;
		flex-direction: column;

		*:not(.dropdown):not(.dropdown *) {
			// Each element needs to be able to see if something is hovering it
			// As such, giving a margin to an element will break the drag and drop system
			margin: 0;
			word-break: break-word;
			font-size: 14px;
			font-weight: 400;
			overflow: hidden;
		}

		textarea {
			min-width: 3em;
			padding: 0px;
		}

		// When we are not exporting, all inputs should have the grab cursor
		input {
			cursor: grab;
		}

		&.empty {
			color: var(--clr-alabaster-600);
		}

		& > *:first-child {
			border: 0px solid transparent;
			transition: border-radius ease-in-out .25s,
						border-color ease-in-out .25s,
						box-shadow ease-in-out .25s;
		}

		&.selected > *:first-child {
			border-color: var(--clr-primary-200);
			box-shadow: inset 0px 0px .25em var(--clr-primary-400);
			border-radius: .25em;
		}

		.label {
			@extend .caption;
		}

		&:hover > *:first-child {
			border-color: var(--clr-primary-100);
			box-shadow: inset 0px 0px .25em var(--clr-primary-200);
		}

		&.selected {
			&.editing > textarea {
				cursor: text;
				overflow: hidden;
				z-index: 500;
			}

			& > label + *,
			& > .label + *,
			& > *:first-child:not(label):not(.label) {
				border-color: var(--clr-primary-200);
				box-shadow: inset 0px 0px .15em var(--clr-primary-500);
				border-radius: .25em;
			}

			label,
			.label {
				color: var(--clr-primary-500);
			}
		}
	}

	.row {
		@include grid($gap: 0, $templateAreas: 'Handle Content Tail', $templateColumns: auto auto 1fr);
		position: relative;
		margin: 0;
		width: 100%;
		background-color: white;
		opacity: 1;

		&.drag-over {
			background-color: var(--clr-cinnabar-100);

			&.can-drop {
				background-color: var(--clr-primary-100);
			}
		}

		&.dragging {
			background-color: var(--clr-primary-100);
			border-radius: .25em;
		}

		& > .skeleton {
			grid-area: Tail;
			background-color: var(--clr-primary-100);
			border-radius: .25em;
			height: 0;
			display: none;

			&.show {
				height: 1.5em;
				display: block;
			}
		}

		.content {
			grid-area: Content;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			max-width: 100%;
			overflow: hidden;

			* {
				margin: 0;
				flex-shrink: 1;
			}
		}

		.drag-handle-wrapper {
			grid-area: Handle;
			position: absolute;
			height: 100%;
			width: 0.4em;
			top: 0;
			background-color: white;
			cursor: grab;

			.drag-handle {
				height: calc(100% - 2px);
				border-left: .25em solid var(--clr-eucalyptus-500);
			}
		}

		.tail {
			grid-area: Tail;
		}
	}

	.element-type {
		@include grid($columnGap: .75em, $rowGap: 0, $templateAreas: "Handle Icon Title" "Handle Icon Description", $templateColumns: auto auto 1fr);

		padding: .5em;
		align-items: center;
		user-select: none;
		cursor: grab;
		background: transparent;
		border: 1px solid transparent;
		border-radius: .25em;
		max-width: 440px;
		transition: border-color .2s ease-in-out;

		&.drag-preview {
			box-shadow: 0px .5em .5em #00000029;
			border: 1px solid var(--clr-primary-500);
			background-color: white;
		}

		&.dragging {
			background: var(--clr-alabaster-200);

			* {
				visibility: hidden; // allows us to show the position of the dragged element with its exact same height
			}

			&:hover {
				border: 1px solid transparent;
			}
		}

		&:hover {
			border: 1px solid var(--clr-primary-500);
		}

		.handle {
			grid-area: Handle;
			width: 9px;
			height: 15px;
			background-color: var(--clr-walkawa-gray-300);
		}

		.icon {
			grid-area: Icon;
			width: 2em;
			height: 2em;
			padding: .25em;
			background-color: var(--clr-alabaster-100);
			border-radius: .25em;

			svg {
				display: block;
				aspect-ratio: 1;
				height: 100%;
				margin: auto;
			}
		}

		.title, .description {
			@include typography.format-paragraph('short');
			margin: 0;
		}

		.title {
			grid-area: Title;
			font-weight: 600;
			color: var(--clr-text);
		}

		.description {
			grid-area: Description;
			color: var(--clr-alabaster-600);
		}
	}
}

// Export mode
.template-editor.export {
	.editor {
		.drag-handle-wrapper {
			display: none;
		}

		.element,
		.element * {
			cursor: auto;

			label,
			.label {
				cursor: pointer;
			}
			input {
				cursor: text;
			}
		}
	}
	aside {
		.editor-header {
			.template-information {
				input:disabled[name="name"],
				input:disabled[name="description"] {
					cursor: default !important;
				}
			}
		}
		.entities {
			grid-row-gap: .5em;

			.header {
				cursor: auto;
			}

			ul {
				padding: 0;
				margin: 0;
			}

			button.expand {
				& > svg {
					transition: transform .25s ease-in-out;
				}

				&[aria-expanded="true"] > svg {
					transform: rotate(180deg);
				}
			}
		}
	}

	.element {
		cursor: pointer;

		input {
			cursor: text;
		}
	}
}

.image-selection-button {
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: center;
}

.font-options-container {
	display: grid;
	grid-template-columns: 1fr auto 6fr;
	grid-gap: .5em;
	padding-top: .25em;
	padding-bottom: .25em;
	color: #777c8a;

	.font-family-select {
		height: 2em;
		font-size: 1rem;
		width: 100%;
	}

	.format-options {
		background: #FAFAFA;
		border-radius: 6px;
		padding: .25em;
		height: 2em;
		width: 2em;
	}

	.font-size-input {
		height: 2em;
		border: 1px solid #B7BCC7;
		color: #777c8a;
		
		&:focus {
			outline: none;
			border: 1px solid var(--clr-primary-300);
		}
	}
}


.format-options-container {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: .5em;
	padding-top: .25em;
	padding-bottom: .25em;

	.font-options {
		background: #FAFAFA;
		border-radius: 6px;
		padding: .25em;
	}

	.format-options {
		background: #FAFAFA;
		border-radius: 6px;
		padding: .25em;
	}

	button.format-button {
		// height: 1rem;
		background-color: transparent;
		border-color: transparent;
		padding: 0.25rem;
		aspect-ratio: 0.5em;
		border-radius: 6px;
		min-height: 0;
		color: #777c8a;
		
		&:hover {
			background-color: #eeeeee;
			border-color: transparent;
			color: black;
		}

		&.enabled {
			background: #E5E8EA 0% 0% no-repeat padding-box;
			color: var(--clr-primary-500);
		}
	}

	.format-buttons-container {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: .2em;
	}
}
