div.module {
	padding-left: 1rem;
}

div.sub-module {
	padding-left: 2rem;
}

div.access-right {
	padding-left: 3rem;
}