@mixin visualisation {
	display: flex;
	flex-direction: column;
	gap: .5em;
	margin: 0 auto auto auto;

	.actions {
		display: flex;
		flex-direction: row-reverse;
		gap: .5rem;
	
		button {
			margin-top: .5rem;
		}
	}

	.contents {
		display: flex;
		flex-direction: column;
		border-radius: .25rem;
		border: solid 1px var(--clr-primary-500);
		padding: .5em;
		background-color: white;
	}

	table {

		td {
			padding: .5rem;

			&.number {
				white-space: nowrap;
				text-align: right;
			}

			&.subtotal {
				white-space: nowrap;
				text-align: right;
				font-weight: bold;
				padding: .5rem;
				height: 100%;
			}
		}

		thead {	
			&.standard {
				background-color: var(--clr-primary-500);
				color: white;
			}
	
			&.archi {
				background-color: white;
				color: var(--clr-primary-500);
			}

			th {
				padding-left: .5rem;
				padding-right: .5rem;

				&.title {
					padding: .5rem;
				}
	
				&.right {
					text-align: right;
				}
	
				&.archi {
					background-color: var(--clr-primary-500);
					color: white;	
				}
	
				&.archi-right {
					background-color: var(--clr-primary-500);
					color: white;
					text-align: right;
				}

				table {
					width: 100%;
				}
			}
		}
	
		tbody {
			tr {
				&:nth-child(odd) {
					background-color: var(--clr-alabaster-100);
		  		}
				
				&:nth-child(even) {
					background-color: var(--clr-primary-100);
				}

				&.nolastchild {
					&:nth-child(odd) {
						background-color: var(--clr-alabaster-100);
					  }
					
					&:nth-child(even) {
						background-color: var(--clr-primary-100);
					}
				}
			}
		}

		tfoot {
			tr {
				&.standard {
					background-color: initial;
				}
		
				&.extra-header {
					background-color: var(--clr-primary-500);
					color: white;
				}
		
				&.extra-detail {
					background-color: white;
					color: var(--clr-primary-500);
				}
	
				td {
					padding: .5rem;
	
					&.total {
						background-color: var(--clr-primary-500);
						color: white;
						white-space: nowrap;
						text-align: right;
						font-weight: bold;
						padding: .5rem;
						height: 100%;
					}

					&.padded {
						padding-top: 21px;
						padding-bottom: 21px;
					}
				}

				th {
					padding: .5rem;
		
					&.right {
						text-align: right;
					}
				}
			}
		}
	}
}

.visualisation {
	@include visualisation;
	width: fit-content;
	min-width: 50%;
	max-width: 100%;
}

.partnervisualisation {
	@include visualisation();
	width: 80%;
	table {
		tbody {
			tr {
				td {
					min-width: 80%;
				}
			}
		}
	}
}
