@use 'styles/components/_buttons.scss';
@use 'styles/abstracts/_sizes' as sizes;


table.quotation-total {
	margin-left: auto;
	margin-right: auto;

	th {
		background-color: rgba(204, 204, 204, 0.5);
		text-align: center;
		font-weight: bold;
		padding: .5rem 1rem;
	}

	td {
		background-color: rgba(238, 238, 238, 0.5);
		text-align: center;
		font-weight: bold;
		padding: .5rem 1rem;
	}
}

table.quotation-grid {
	// Inputs inside an quotation table should not have a label displayed.
	label,
	span.hint,
	span.error-message {
		display: none;
	}

	.react-select {
		text-align: start;
	}

	th {
		white-space: nowrap;

		&.bottom-left {
			text-align: left;
			vertical-align: bottom;
		}

		.label-right {
			display: flex;
			flex-direction: column;
			text-align: right;
			padding: 8px 12px;
			height: 2.5rem;
		}

		.column-actions {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-around;
			gap: .5rem;
		}

		.center {
			text-align: center;
			font-weight: bold;
			padding: 1rem 0 0 0;
		}

		.right {
			text-align: right;
			font-weight: bold;
			padding: .25rem;
		}

		button.add-column {
			width: 100%;
			&:hover {
				border-color: transparent;
			}
		}
	}

	td {
		white-space: nowrap;

		&.subtotal {
			background-color: rgba(204, 204, 204, 0.5);
			text-align: right;
			font-weight: bold;
			padding: .5rem;
		}

		.row-actions {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-around;
			gap: .5rem;
		}

		.row-total-right {
			background-color: rgba(238, 238, 238, 0.5);
			text-align: right;
			font-weight: bold;
			padding: .5rem;
		}

		.column-total-right {
			background-color: rgba(238, 238, 238, 0.5);
			text-align: right;
			font-weight: bold;
			padding: .25rem;
		}

		.check-right {
			color: rgb(255, 0, 0);
			text-align: right;
			font-style: italic;
			padding: .25rem;
		}
	}
}
