.targetPartner-details-page {
	display: grid;
	grid-template-rows: auto 1fr auto;
	height: 100%;
	width: 100%;
	overflow: auto;
}

.targetPartner-details-header {
	display: grid;
	grid-template-rows: auto 1fr auto;
	height: fit-content;
	width: 100%;
	overflow: auto;
}

.targetPartner-details-content {
	display: grid;
	grid-template-columns: 33% auto;
	overflow: auto;
	gap: 1em;
}

.targetPartner-details-list {
	display: flex;
	flex-direction: column;
}

.targetPartner-details-fiche {
	display: grid;
	grid-template-rows: auto 1fr auto;
	height: 100%;
	width: 100%;
	overflow: auto;
}

.targetPartner-details-fiche-header {
	display: grid;
	grid-template-rows: 1fr auto;
	height: fit-content;
	width: 100%;
}

.targetPartner-details-fiche-header-status {
	display: flex;
	flex-direction: row;
	width: fit-content;

	button {
		border-radius: 4px;
		box-shadow: 0 0.1em 0.5em rgba(168, 176, 185, 0.3);
	
		&:not(:disabled):not(.loading) {
			&:hover {
				border-color: transparent;
				background-color: var(--clr-primary-500);
				color: var(--clr-alabaster-100);
			}
			
			&:active {
				border-color: transparent;
				background-color: var(--clr-primary-700);
				color: var(--clr-alabaster-100);
			}
		}
	
		&:disabled {
			border-color: transparent;
			background-color: var(--clr-alabaster-200);
			color: var(--clr-alabaster-800);
		}
	}
}

.targetPartner-details-fiche-header-status-chevron {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: .25em;
	padding: .5em;
	border-radius: 4px;
	border: solid 1px var(--clr-primary-500);

	p {
		margin: 0;
	}

	span {
		margin: 0;
		font-size: 50%;
		font-weight: bold;
	}
}

.targetPartner-details-fiche-header-partner {
	display: grid;
	grid-template-columns: auto 1fr;
	height: fit-content;
	width: 100%;
	margin-top: 10px;
	margin-bottom: 10px;
}

.targetPartner-partner {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.targetPartner-partner-name {
	display: flex;
	flex-direction: column;
	width: fit-content;

	p {
		margin: 0;
	}

	span {
		margin: 0;
		font-size: 120%;
		text-transform: capitalize;
	}
}

.targetPartner-partner-edit {
	display: inline-block;

	button {
		background-color: transparent;
		border-color: transparent;
		padding: .25rem;
		aspect-ratio: 1;
		border-radius: 50%;
	
		&:not(:disabled):not(.loading) {
			&:hover {
				border-color: transparent;
				background-color: var(--clr-primary-500);
				color: var(--clr-alabaster-100);
			}
			
			&:active {
				border-color: transparent;
				background-color: var(--clr-primary-700);
				color: var(--clr-alabaster-100);
			}
		}
	
		&:disabled {
			border-color: transparent;
			background-color: var(--clr-alabaster-200);
			color: var(--clr-alabaster-800);
		}
	}
}

.targetPartner-footer-info-icon {
	display: flex;
	margin-right: 5px;
}

.targetPartner-details-fiche-content {
	display: grid;
	grid-template-columns: 2fr 1fr;
}

.targetPartner-history {
	display: flex;
	flex-direction: column;
	gap: 1em;
	width: 100%;

	button {
		width: 93%;
		margin-left: 5%;
		border-radius: 4px;
		box-shadow: 0 0.1em 0.5em rgba(168, 176, 185, 0.3);
	
		&:not(:disabled):not(.loading) {
			&:hover {
				border-color: transparent;
				background-color: var(--clr-primary-500);
				color: var(--clr-alabaster-100);
			}
			
			&:active {
				border-color: transparent;
				background-color: var(--clr-primary-700);
				color: var(--clr-alabaster-100);
			}
		}
	
		&:disabled {
			border-color: transparent;
			background-color: var(--clr-alabaster-200);
			color: var(--clr-alabaster-800);
		}
	}
}

.targetPartner-history-card {
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: row;
}

.targetPartner-history-card-avatar {
	width: 5%;

	img {
		max-height: 32px;
		border-radius: 50%;
	}
}

.targetPartner-history-card-content {
	width: 93%;
	height: fit-content;
	border-radius: 4px;
	box-shadow: 0 0.1em 0.5em rgba(168, 176, 185, 0.3);
	background-color: white;
	display: grid;
	grid-template-rows: auto auto;
	gap: 1em;
	padding: 5px;
}

.targetPartner-history-card-content-footer {
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: row;
	gap: 1em;

	p {
		margin: 0;
		gap: 5em;
	}

	button {
		background-color: transparent;
		border-color: transparent;
		padding: .25rem;
		aspect-ratio: 1;
		border-radius: 50%;
		width: 3em;
		height: 3em;
	
		&:not(:disabled):not(.loading) {
			&:hover {
				border-color: transparent;
				background-color: var(--clr-primary-500);
				color: var(--clr-alabaster-100);
			}
			
			&:active {
				border-color: transparent;
				background-color: var(--clr-primary-700);
				color: var(--clr-alabaster-100);
			}
		}
	
		&:disabled {
			border-color: transparent;
			background-color: var(--clr-alabaster-200);
			color: var(--clr-alabaster-800);
		}
	}
}

.targetPartner-footer-info {
	width: fit-content;
	height: fit-content;
	display: flex;
	flex-direction: row;
	gap: 0.25em;

	span {
		margin: 0;
		font-size: 50%;
		font-weight: bold;
	}
}

.targetPartner-contacts {
	width: 100%;
	overflow: auto;
	border-left: 1px solid #ccc;
	display: flex;
	flex-direction: column;
	gap: 1em;
}

.targetPartner-contacts-button {
	width: 96%;
	margin-left: 2%;
	border-radius: 4px;
	box-shadow: 0 0.1em 0.5em rgba(168, 176, 185, 0.3);

	&:not(:disabled):not(.loading) {
		&:hover {
			border-color: transparent;
			background-color: var(--clr-primary-500);
			color: var(--clr-alabaster-100);
		}
		
		&:active {
			border-color: transparent;
			background-color: var(--clr-primary-700);
			color: var(--clr-alabaster-100);
		}
	}

	&:disabled {
		border-color: transparent;
		background-color: var(--clr-alabaster-200);
		color: var(--clr-alabaster-800);
	}
}

.targetPartner-contact-card {
	display: flex;
	flex-direction: row;
	width: 100%;
	padding-left: 2%;
	justify-content: space-between;
}

.targetPartner-contact-card-name {
	display: flex;
	flex-direction: column;

	p {
		margin: 0;
	}

	span {
		font-size: 80%;
	}
}

.targetPartner-contact-card-actions {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	button {
		background-color: transparent;
		border-color: transparent;
		padding: .25rem;
		aspect-ratio: 1;
		border-radius: 50%;
	
		&:not(:disabled):not(.loading) {
			&:hover {
				border-color: transparent;
				background-color: var(--clr-primary-500);
				color: var(--clr-alabaster-100);
			}
			
			&:active {
				border-color: transparent;
				background-color: var(--clr-primary-700);
				color: var(--clr-alabaster-100);
			}
		}
	
		&:disabled {
			border-color: transparent;
			background-color: var(--clr-alabaster-200);
			color: var(--clr-alabaster-800);
		}
	}
}
