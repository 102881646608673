
.resize-handle {
	position: absolute;
	overflow: hidden;
	background-color: transparent;
	right: 0;
	width: 20px;
	height: 20px;
	color:rgba(0, 0, 0, 0.3);
	z-index: 100;
	cursor: ns-resize;

	&:focus {
		cursor: ns-resize;
	}

	&.top-handle {
		top: -4px;
	}
	&.bottom-handle {
		bottom: 0;
	}
}
