@import 'styles/base/_mixins.scss';

.contact-list-item {
	list-style: none;

	img, [role="img"] {
		border-radius: 50%;
		height: 2.5rem;
		aspect-ratio: 1;
	}
	button {
		display: grid;
		grid-template-columns: auto 1fr;
		align-items: center;
		gap: .5rem;

		width: 100%;
		padding: .25rem;
		text-align: left;
	}

	address {
		display: flex;
		flex-direction: column;
		gap: .25rem;
		font-style: normal;

		& > *:last-child {
			font-weight: 300;
			font-size: .8rem;
		}
	}

	&--skeleton {
		@extend .contact-list-item;
		width: 100%;
		height: 3rem;

		p {
			height: 1.32rem;
			width: 100%;
			border-radius: calc(1.32rem / 4);

			&:last-child {
				height: 1rem;
				width: 50%;
			}
		}

		img, [role="img"], p {
			@include skeleton-ui;
		}
	}
}

@include skeleton-ui-animation;
