@use 'styles/abstracts/_sizes' as sizes;

.tag-list {
	display: flex;
	flex-wrap: wrap;
	gap: .5rem;
	list-style: none;
	padding: 0;
	overflow: hidden;

	li {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 0 0 .15rem 0;
	}
}

.tag {
	font-weight: 600;
	font-size: sizes.$caption-font-size;
	background-color: var(--clr-alabaster-200);
	padding: .25rem .5rem;
	border-radius: .5rem;
}
