@use 'styles/components/_buttons';

a {
	position: relative;
	font-size: .9rem;
	color: var(--clr-primary-500);
	font-weight: 500;
	padding: 0;
	transition: color ease .32s;
	text-decoration: none;

	&.subtle {
		color: var(--clr-walkawa-gray-500);
	}

	&:not(.button) {
		&:not(.logo)::after {
			content: '';
			position: absolute;
			transform: scaleX(0);
			height: 2px;
			width: 100%;
			bottom: 0;
			left: 0;
			background-color: var(--clr-primary-500);
			transform-origin: bottom right;
			transition: transform ease-out .2s;
		}
	
		&:not(.logo):hover::after {
			transform: scaleX(1);
			transform-origin: bottom left;
		}
	
		&:not(.logo):hover {
			color: var(--clr-primary-600);	
		}
	
		&:not(.logo):focus-visible {
			outline: solid 1px var(--clr-primary-500);
			outline-offset: 2px;
		}
	}
}

a.button {
	@extend button;
}

p > a,
p > * > a {
	font-size: 1rem;
}

a[disabled], a:disabled {
	pointer-events: none;
}
