div.header {
	display: flex;
	align-items: center;

	button {
		background-color: transparent;
		border-color: transparent;
		padding-left: '20px';
		
		&:not(:disabled) {
			&:hover, &:active {
				background-color: transparent;
				border-color: transparent;
			}
	
			&:hover svg {
				stroke: var(--clr-primary-500);
			}
			&:active svg {
				stroke: var(--clr-primary-700);
			}
		}

		svg {
			stroke: #878F9A;
		}
	}
}

div.user-email {
	display: grid;
	grid-template-rows: 1fr 1fr;
	font-size: 80%;
	text-align: center;
	margin-bottom: 1.5rem;

	.email {
		font-size: 120%;
		color: var(--clr-primary-500);
	}
}

div.user-username {
	display: grid;
	grid-template-columns: 60px 1fr;
}
