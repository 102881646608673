.targeting-details-page {
	display: grid;
	grid-template-rows: auto 1fr auto;
	height: 100%;
	width: 100%;
	overflow: auto;
}

.targeting-details-header {
	display: grid;
	grid-template-rows: auto 1fr auto;
	height: fit-content;
	width: 100%;
	overflow: auto;
}

.targeting-details-content {
	display: grid;
	grid-template-rows: auto 1fr auto;
	width: 100%;
	overflow: auto;
}

.targeting-details-header-container {
	display: grid;
	grid-template-columns: 1fr 2fr;
	margin-bottom: 1em;
}

.targeting-details-header-left {
	display: flex;
	flex-direction:column;
	width: 100%;
	gap: .25em;

	p, h1, h2, h3, h4, h5 {
		margin: 0;
	}
}

.targeting-details-header-right {
	display: inline-block;

	p, h1, h2, h3, h5 {
		margin: 0;
	}
}
