@use 'styles/abstracts/_sizes' as sizes;
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

* {
	font-family: 'IBM Plex Sans, Helvetica Neue, Arial, sans-serif';
	line-height: sizes.$line-height;
}

p {
	font-size: 1rem;
	font-weight: 400;
	letter-spacing: 0px;
}

.caption {
	font-size: sizes.$caption-font-size;
	font-weight: 400;
	letter-spacing: .32px;
}

@mixin format-paragraph($type) {
	@if ($type == 'short'){
		font-size: sizes.$short-paragraph-font-size;
		font-weight: 400;
		letter-spacing: .16px;
	}
}

.short {
	font-size: sizes.$short-paragraph-font-size;
	font-weight: 400;
	letter-spacing: .16px;
}

h1 {
	font-size: 2.488rem;
	font-weight: 400;
	letter-spacing: .16px;
}

h2 {
	font-size: 2.074rem;
	font-weight: 400;
	letter-spacing: .16px;
}

h3 {
	font-size: 1.728rem;
	font-weight: 400;
	letter-spacing: .16px;
}

h4 {
	font-size: 1.44rem;
	font-weight: 400;
	letter-spacing: .16px;
}

h5 {
	font-size: 1.2rem;
	font-weight: 400;
	letter-spacing: .16px;
}

@media (min-width: 750px) {
	h1 {
		font-size: 3.052rem;
	}
	
	h2 {
		font-size: 2.441rem;
	}
	
	h3 {
		font-size: 1.953rem;
	}
	
	h4 {
		font-size: 1.563rem;
	}
	
	h5 {
		font-size: 1.25rem;
	}
}
