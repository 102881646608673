#root > nav:not([aria-label="Breadcrumbs"]) {
	background-color: var(--clr-alabaster-100);
	width: 248px;
	box-shadow: 1px 0px 3px var(--clr-alabaster-200);
	user-select: none;
	overflow: auto;
	
	* {
		text-decoration: none;
		color: var(--clr-alabaster-700);	
		font-size: 14px;
		line-height: 21px;
		text-align: start;
	}

	ul {
		list-style: none;
		padding: 0;
	}

	& > ul {
		margin-top: 0;
		height: fit-content;
		display: flex;
		flex-direction: column;
	}

	button {
		position: relative;
		display: grid;
		width: 100%;
		padding: 4px 8px 4px 0;
		grid-template-columns: auto 1fr;
		grid-column-gap: 8px;
		justify-items: flex-start;
		align-items: center;
		font-weight: 500;
		background-color: inherit !important;
		border: none;
		cursor: pointer;

		&::after {
			content: '\25BE';
			position: absolute;
			right: 0;
			transition: transform cubic-bezier(0.4, 0, 0.5, 0.9) .32s;
		}

		& > div:first-child > div:first-child {
			height: 24px;
			grid-area: icon;
		}

		& svg {
			height: 1.5rem;
			max-height: 1.5rem !important;
			max-width: 1.5rem !important;
			fill: var(--clr-alabaster-700);
			stroke: var(--clr-alabaster-700);
		}
	}

	& > ul > li {
		padding-bottom: 8px;
		padding-top: 8px;
		padding-right: 16px;


		&.open, &:hover {
			background-color: #F1F1F5;
			border-left: solid 4px var(--clr-primary-500);
			color: var(--clr-primary-500);
		}

		& > button {
			padding: 8px;
		}
	}

	li {
		display: grid;
		grid-template-rows: auto 1fr;
		grid-column-gap: 16px;
		align-items: center;
		background-color: inherit;
		padding-left: 16px;
		max-width: 100%;

		& > ul {
			grid-row: 2;
			clip: rect(0 0 0 0);
			clip-path: inset(1%); /* Originaly set to 50% but working only under Firefox */
			max-height: 0;
			visibility: hidden;
			transition: max-height cubic-bezier(0.4, 0, 0.5, 0.9) .5s,
						clip cubic-bezier(0.4, 0, 0.5, 0.9) .5s,
						clip-path cubic-bezier(0.4, 0, 0.5, 0.9) .5s;
		}

		&.open > ul {
			visibility: visible;
		}

		&:hover > *,
		&:hover svg,
		&.open > button,
		&.open > button svg {
			color: var(--clr-primary-500);
			stroke: var(--clr-primary-500);
			fill: var(--clr-primary-500);
		}

		&.open > button::after {
			transform: rotate(180deg);
		}

		&.open > button::after {
			transform: rotate(180deg);
		}

		&.open > ul {
			clip: auto;
			clip-path: border-box;
		}

		a {
			position: relative;
			width: fit-content;
			max-width: 100%;

			&[aria-current="page"] {
				color: var(--clr-primary-500);
			}
		}
	}

	& > .appSvg {
		width: 248px;
		padding: 12px 64px 6px 58px;
		stroke: var(--clr-alabaster-900);
		fill: var(--clr-alabaster-900);
		opacity: 0.2;
	}
}
