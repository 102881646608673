$min-input-height: 2.5rem !default;
$min-button-height: $min-input-height !default;

$line-height: 1.32 !default;
$short-paragraph-font-size: .875rem !default;
$caption-font-size: 0.75rem !default;
$table-font-size: .9rem !default;

$input-padding-top: 8px;
$input-padding-right: 12px;
$input-padding-bottom: 8px;
$input-padding-left: 8px;
$input-with-leading-icon-padding-left: 2em;
$input-with-trailing-icon-padding-right: 2em;
$input-icon-max-height: 1.2em;
$input-icon-max-width: 1.2em;
