div.edition-actions {
	display: flex;
	gap: .5em;

	span.hint,
	span.error-message {
		display: none;
	}

	>.react-select {
		min-width: 15em;
	}
}