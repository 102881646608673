@use 'styles/abstracts/_colors' as colors;

.page-indicator {
	padding: .5em;
	border-color: transparent;

	&[aria-current="true"],
	&:hover {
		border: solid 1px var(--clr-primary-500);
		background-color: rgba(map-get(colors.$primary, 100), 0.2);
		color: var(--clr-primary-500);
	}

	svg {
		display: none;
	}

	&[hidden] {
		width: 0;
		padding: 0;
		visibility: hidden;
	}
}
