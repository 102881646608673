@use 'styles/abstracts/_colors' as colors;

.contact-card {
	position: relative;
	display: grid;
	grid-template-rows: repeat(3, auto);
	gap: 2rem;
	background-color: white;
	border-radius: .5rem;
	border: solid 1px transparent;
	
	z-index: 100;
	background: #fff;
	margin: auto;

	padding: 2rem 2.5rem;

	img {
		position: absolute;
		border-radius: 50%;
		inset: 0 0 auto 0;
		margin: auto;
		transform: translateY(-50%);
		border: solid .25rem white;
	}

	header {
		display: flex;
		flex-direction: column;

		h2 {
			margin-bottom: 0;
		}

		a {
			width: fit-content;
			align-self: center;
		}

		.actions {
			display: flex;
			gap: 1rem;
			justify-content: center;
			align-items: center;
			margin-top: 1.5rem;


			button {
				padding: 1rem;
				
				svg {
					height: 1.25rem;
					stroke: white;
				}

				&:enabled {
					&:hover {
						border-color: transparent;
	
						svg {
							stroke: white;
						}	
					}
	
					&[name="phone"] {
						background-color: var(--clr-shamrock-600);
	
						&:hover {
							background-color: var(--clr-shamrock-700);
						}
					}
					
					&[name="email"] {
						background-color: var(--clr-primary-500);
	
						&:hover {
							background-color: var(--clr-primary-600);
						}
					}
				}
				&:disabled {
					background-color: var(--clr-alabaster-200);
					svg {
						stroke: var(--clr-alabaster-700);
					}
				}
			}

		}

		.close-button {
			position: absolute;
			inset: .5rem .5em auto auto;
		}
	}

	main {
		display: grid;
		grid-template-rows: repeat(auto-fit, minmax(0, 1fr));
		gap: 1rem;

		p {
			margin: 0;
		}

		& > div {
			display: grid;
			grid-template-rows: auto auto;
			gap: .25rem;

			& > p:first-child {
				font-weight: 600;
			}
			
			& > p:last-child {
				color: var(--clr-walkawa-gray-600);
			}
		}
	}

	footer {
		display: flex;
		justify-content: center;
	}
}

.modal-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1050;
	width: 100%;
	height: 100%;
	outline: 0;
	display: flex;
	align-items: center;
	background-color: rgba(map-get(map-get(colors.$colors, 'walkawa-gray'), 900), .2);
}