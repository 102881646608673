@import 'styles/base/_mixins.scss';

.dropdown-list-item, .dropdown-list-item:hover {
	border: none;
	box-shadow: none !important;
}

.dropdown-no-padding {
	padding: 0;
}
