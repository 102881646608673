
.date-picker-wrapper {
	position: relative;
	
	.date-input {
		width: max-content;
	}
}

// Style des select complet

.calendar {
	z-index: 4000;
	position: absolute;
	top: 40px;
	padding: 0;
	margin: 5px;
	width: 320px;
	height: 0;
	border-radius: 10px;
	overflow: hidden;
	list-style: none;
	background: #ffffff;
	opacity: 0;
	box-shadow: 0px 0px 5px #afafaf;
	transition: opacity ease-in-out .4s;
	
	display: grid;
	grid-template-rows: repeat(3, auto);
	row-gap: 24px;

	&.open {
		opacity: 1;
		height: auto;
		padding: 24px;
	}

	.calendar-header {
		margin: 0 auto;
		width: max-content;

		select {
			background-color: transparent;
			border: none;
			padding: 0 1em 0 0;
			margin: 0;
			font-family: inherit;
			font-size: inherit;
			cursor: inherit;
			line-height: inherit;
			transition: ease-in-out .22s;
			text-align: end;
		}
	}

	.calendar-wrapper {
		margin: 0 auto;
		width: max-content;
		display: grid;
		grid-template-columns: repeat(7, 24px);
		grid-template-rows: repeat(7, 24px);
		grid-gap: 12px 16px;
		justify-items: center;
		align-items: center;
		
		.calendar-head {
			color: var(--functional-tuna);
			font-weight: bold;
			letter-spacing: 0px;
			color: #8C8C8C;
		}
		
		.calendar-day {
			border:none;
			min-height: initial;
			background-color: transparent;
			color: var(--functional-peppercorn);
			height: 28px;
			width: 24px;
			letter-spacing: 0px;
			color: #241C15;
		
			
			&.other-month {
				letter-spacing: var(--unnamed-character-spacing-0);
				color: var(--functional-tuna);
				letter-spacing: 0px;
				color: #8C8C8C;
			}
		
			&.other-month {
				letter-spacing: var(--unnamed-character-spacing-0);
				color: var(--functional-tuna);
				letter-spacing: 0px;
				color: #8C8C8C;
			}
			
			&[aria-current="date"] {
				background: #F1F1F5;
				border-radius: 4px;
				color: var(--clr-primary-400);
			}	
			
			&.focused-day {
				outline: 3px solid var(--clr-primary-400);
				outline-offset: 2px;
			}
			
			&:hover{
				background: var(--clr-primary-200);
				border-radius: 4px;
				color: #FFFFFF;
				transition: .2s;
				cursor: pointer;
			}

			&[aria-pressed="true"] {
				background: var(--clr-primary-500);
				color: #FFFFFF;
				border-radius: 4px;
			}
		}
	}

	.calendar-footer {
		display: grid;
		grid-template-columns: repeat(2, auto);
		column-gap: 8px;

		margin-left: auto;
		margin-right: 0;
		width: max-content;
	}
}