#root > header {
	position: sticky;
	top: 0;
	z-index: 400;
	background-color: #ffffff;
	height: 58px;
	box-shadow: 1px 1px 3px #E9EBED;
	display: grid;
	grid-template-areas: 'logo breadcrumbs userSection';
	grid-column-gap: 32px;
	grid-template-columns: 248px 1fr auto; /* Note : the 248px is linked to the navbar's width. */
	align-items: center;

	.logo {
		font-size: 1.25rem;
	}

	nav[aria-label="Breadcrumbs"] {
		grid-area: breadcrumbs;
		display: flex;
		align-items: center;

		& > button {
			border-radius: 12px;
			background-color: var(--clr-alabaster-100);
			color: var(--clr-alabaster-600);
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;
			padding: 4px 12px;
			border: none;
			cursor: pointer;

			&:hover {
				color: var(--clr-alabaster-900);
				background-color: var(--clr-alabaster-200);
			}
		}

		svg {
			stroke: var(--clr-alabaster-600);
			stroke-width: 2px;
			max-height: 20px;
			width: 20px;
		}
	}

	.user-section {
		grid-area: userSection;
		position: relative;
		display: grid;
		align-items: center;
		grid-template-columns: 1fr auto;
		grid-gap: 12px;
		margin-right: 12px;

		* {
			cursor: pointer;
		}

		& > *:not(img):not(.user-menu) {
			max-height: 20px;
			stroke: var(--clr-alabaster-700);
		}

		& > .username {
			color: var(--clr-alabaster-700);
			text-align: right;
		}

		img {
			max-height: 32px;
			border-radius: 50%;
		}

		& > button {
			border: none;
			background-color: transparent;
			padding: 0;

			* {
				width: auto;
				max-height: inherit;
				stroke: var(--clr-alabaster-700);
			}
		}

		.user-menu {
			z-index: 4000;
			position: absolute;
			top: 24px;
			right: 0px;
			width: max-content;
			height: 0;
			overflow: hidden;
			list-style: none;
			background: #ffffff;
			box-shadow: 1px 0px 3px var(--clr-alabaster-700);
			padding: 0;
			transition: height ease-in-out .22s;
			
			&.open {
				height: calc(2 * 40px); // Update the first value with the number of items in the menu.
			}

			button {
				height: 40px;
				position: relative;
				display: grid;
				width: 100%;
				padding: 8px 16px;
				grid-template-columns: auto 1fr;
				grid-column-gap: 8px;
				justify-items: flex-start;
				align-items: center;
				font-weight: 500;
				background-color: inherit;
				border: none;
				cursor: pointer;

				&:hover {
					color: #5E5BD2;
				}
			}
		}
	}
}
