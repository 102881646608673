.modal.image-preview-modal .modal-content {
	max-width: 60vw;
	min-width: 10vw;
	width: auto;
}

.image-preview-modal .image-preview {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
