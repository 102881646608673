@use 'styles/components/_buttons' as buttons;

.tab-list[role="tablist"] {
	display: grid;
	grid-auto-columns: minmax(10rem, max-content);
	box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
	overflow-x: auto;
}

.tab[role="tab"] {
	@extend button;
	border-radius: 0;
	background-color: transparent;
	border-color: transparent;
	grid-row: 1;

	&:first-child {
		border-top-left-radius: .25rem;
	}

	&[aria-selected="true"] {
		border-bottom: solid 2px var(--clr-primary-500);
		color: var(--clr-primary-500);
	}
}
