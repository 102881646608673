.data-header {
	display: flex;
	flex-direction: row;

	.identity {
		display: flex;
		flex-direction: column;
		margin-bottom: 1rem;

		h1, h2, h3, h4, h5, h6 {
			margin: 0;
			color: var(--clr-primary-500);
		}

		p {
			margin: 0;
			color: #878F9A;
		}
	}
	.metadata {
		display: flex;
		flex-direction: column;
		margin-left: auto;
		color: #878F9A;
		min-width: 14rem;

		h1, h2, h3, h4, h5 {
			margin: 0;
		}

		p {
			margin: 0;
			font-size: .8rem;
		}
	}
}
