@use 'styles/base/_typography' as typography;
@use 'styles/abstracts/_sizes' as sizes;

.input-wrapper {
	display: flex;
	flex-direction: column;
	gap: .25rem;

	span:is(.hint, .error-message) {
		&:empty::before {
			content: '\200b';
		}
	}

	&.disabled,
	&.disabled * {
		cursor: not-allowed !important;
		color: var(--clr-alabaster-600) !important;
	}

	&.hidden,
	&.hidden * {
		display: none;
	}
}

.checkbox-wrapper {
	flex-direction: row;
	align-items: center;

	label {
		flex-shrink: 1;
	}
}

input[type="checkbox"] {
	min-height: initial;
	cursor: pointer;
	height: 1rem;
	width: 1rem;
	padding: .2rem;
	min-width: initial;
	border-radius: .25rem;
}

input,
div[contenteditable="true"], // This is to prepare for the inelement Multi format
textarea,
.file-input { 
	@extend p;
	display: block;
	min-height: sizes.$min-input-height;
	width: 100%;
	min-width: 12rem;
	color: var(--clr-text);
	background-color: var(--clr-alabaster-100);
	border: solid 1px var(--clr-walkawa-gray-500);
	padding: sizes.$input-padding-top sizes.$input-padding-right sizes.$input-padding-bottom sizes.$input-padding-left;
	border-radius: .25em;
	cursor: text;
	transition: border ease .2s, outline ease .2s;

	&[aria-invalid='true'] {
		border-color: var(--clr-cinnabar-500);
	}

	&.seamless {
		border-color: transparent;
		background-color: transparent;
		min-height: auto;
		resize: none;
		border: none;

		&.editing {
			resize: both;
			padding: 0;

			> * :hover {
				cursor: text;
			}
		}

		&:focus {
			outline: none;
		}

		// We override the normal behaviour so it stays seamless
		&:disabled {
			border-color: transparent;
		}
	}

	&:focus-visible {
		outline: solid 1px var(--clr-primary-500);
		outline-offset: 2px;
		border-color: var(--clr-primary-500);
	}

	& ~ .hint,
	& ~ .error-message {
		display: block;
		font-size: .75rem;
		transition: color ease .2s;
	}

	& ~ .hint {
		color: var(--clr-text);
	}

	&:focus-visible ~ .hint {
		color: var(--clr-primary-500);
	}

	& ~ .error-message {
		color: var(--clr-cinnabar-500);
	}

	&:disabled {
		cursor: not-allowed !important;

		border-color: var(--clr-alabaster-600);

		& ~ svg {
			stroke: var(--clr-alabaster-600);
		}
	}
}

input[type="file"] {
	display: none;
}

.file-input {
	display: grid;
	grid-template-columns: auto 1fr;
	gap: .7rem;
	align-items: center;
	cursor: pointer;

	&:focus-within {
		color: var(--clr-text);
	}
}

.select-input {
	display: grid;
	grid-template-columns: 35% 63%;
	gap: 7px;
}

.date-input, .select-input {
	[aria-invalid='true'] ~ svg {
		stroke: var(--clr-cinnabar-400);
	}

	&:focus-within input, & input:focus-visible {
		outline: solid 1px var(--clr-primary-500);
		outline-offset: 2px;
		border-color: var(--clr-primary-500);
	}

	&:focus-within svg {
		stroke: var(--clr-primary-500);
	}

	& ~ .hint,
	& ~ .error-message {
		display: block;
		font-size: .75rem;
		transition: color ease .2s;
	}

	& ~ .hint {
		color: var(--clr-text);
	}

	& input:focus-visible ~ .hint {
		color: var(--clr-primary-500);
	}

	& ~ .error-message {
		color: var(--clr-cinnabar-500);
	}
}

.react-select {
	min-height: sizes.$min-input-height;
	min-width: 8rem;
	cursor: text;

	input {
		min-height: initial;
		cursor: text;
	}

	& > [class$="control"] {
		min-height: sizes.$min-input-height;
		background-color: var(--clr-alabaster-100);
		cursor: text;

		svg {
			cursor: pointer;
		}
	}

	& span[class$="indicatorSeparator"] {
		margin-left: .25rem;
	}

	& button.select-action {
		min-width: auto;
		min-height: auto;
	}

	& ~ .hint,
	& ~ .error-message {
		display: block;
		font-size: .75rem;
		transition: color ease .2s;
	}

	& ~ .hint {
		color: var(--clr-text);
	}

	& input:focus-visible ~ .hint {
		color: var(--clr-primary-500);
	}

	& ~ .error-message {
		color: var(--clr-cinnabar-500);
	}

	&:disabled,
	&.disabled {
		& > [class$="control"] {
			border-color: var(--clr-alabaster-600);
		}

		svg {
			stroke: var(--clr-alabaster-600);
		}
	}
}

textarea {
	min-height: 40px; // Prevents the user from resizing a text-area below the padding + lineheight of the textarea.
	max-width: 100%;
}

label {
	@extend .caption;
	display: block;
	color: var(--clr-text);
	transition: color ease .2s;
	width: fit-content;
	cursor: pointer;

	&.invalid {
		color: var(--clr-cinnabar-500)
	}

	&.focus {
		color: var(--clr-primary-500);
	}

	&.disabled {
		color: var(--clr-alabaster-600);
		cursor: not-allowed;
	}
}

div:focus-within > label,
label:focus-within {
	color: var(--clr-primary-500);	
}

.icon-input-wrapper {
	position: relative;

	&.disabled,
	&.disabled * {
		cursor: not-allowed !important;
		color: var(--clr-alabaster-600) !important;
	}

	input {
		padding-right: sizes.$input-with-trailing-icon-padding-right;
	}

	i, svg, .icon {
		z-index: 90;
		position: absolute;
		max-height: sizes.$input-icon-max-height;
		max-width: sizes.$input-icon-max-width;
		inset: 50% 0 auto auto;
		stroke: var(--clr-walkawa-gray-400);
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.string-currency-icon {
		z-index: 90;
		position: absolute;
		max-height: sizes.$input-icon-max-height;
		max-width: sizes.$input-icon-max-width;
		inset: 50% 0 auto auto;
		top: 50%;
		transform: translate(-50%, -50%);
		padding-right: .25rem;
		color: var(--clr-walkawa-gray-400);
		font-size: 1.2rem;
		font-weight: normal;
	}

	input:focus-visible ~ i,
	input:focus-visible ~ svg,
	input:focus-visible ~ .icon {
		stroke: var(--clr-primary-500);
	}

	input.invalid ~ i,
	input.invalid ~ svg,
	input.invalid ~ .icon {
		stroke: var(--clr-cinnabar-500);
	}

	&.leading-icon {
		input {
			padding-left: sizes.$input-with-leading-icon-padding-left;
		}

		i, svg, .icon, .string-currency-icon {
			inset: 50% auto auto 0;
			transform: translate(50%, -50%);
		}
	}
}

.number-input {
	input {
		text-align: end;
		min-width: 7rem;
	}
}

.toggle-switch {
	position: relative;
	width: 2.5rem;
	height: 1.5rem;
	border-radius: 1.5rem;

	&:focus-visible {
		outline: solid 1px var(--clr-primary-500);
		outline-offset: 2px;
	}

	input {
		visibility: hidden;

		& + span {
			position: absolute;
			cursor: pointer;
			inset: 0 0 0 0;
			background-color: #ededf0;
			transition: .3s;
			border-radius: 1.5rem;

			&::before {
				position: absolute;
				content: "";
				height: 1rem;
				width: 1rem;
				left: .25rem;
				bottom: .25rem;
				border-radius: 50%;
				transition: .3s;
				background-color: #ededf0;
			}
		}

		&:checked + span {
			background-color: var(--clr-primary-500);

			&::before {
				transform: translateX(1rem);
			}
		}
	}
}
