@use 'styles/abstracts/_colors' as colors;

.modal.media-manager {
	.modal-header {
		display: none;
	}
	
	.modal-content {
		border-radius: 4px;
		width: 1152px;
		max-width: none;
		height: 648px;
		max-height: none;
		padding: 0;
	}
}

.media-manager .mm-content {
	display: grid;
	grid-template-areas:
		"Header Sidebar"
		"Body Sidebar";
	grid-template-columns: 1fr auto;
	grid-template-rows: auto 1fr;
	height: 100%;

	.mm-header {
		grid-area: Header;
		padding: 16px 32px;
		display: grid;
		grid-template-columns: auto 1fr auto;
		column-gap: 8px;
		width: 100%;

		.mm-path {
			display: flex;
			flex-direction: row;
			align-items: center;

			& > button {
				height: 1.875em;
				min-height: unset;
				background: #F3F4F5;
				border: none;
				color: var(--clr-alabaster-700);
			}

			& > svg {
				color: var(--clr-alabaster-700);
				height: 1em;
				stroke-width: 3px;
			}
		}

		.mm-options {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;

			& > button {
				padding: 0;
				width: 3em;
				color: var(--clr-alabaster-700);

				&:hover svg {
					stroke: var(--clr-primary-500);
				}
			}
		}

		.mm-search {
			width: 16.5em;
		}
	}

	.mm-file-drop {
		background: linear-gradient(transparent, var(--clr-alabaster-200), var(--clr-alabaster-200));
	}

	.mm-body {
		grid-area: Body;

		&.mm-grid {
			display: grid;
			grid-template-rows: auto auto 1fr;
			row-gap: 32px;
			padding-left: 32px;
			user-select: none;
			overflow-y: auto;

			.mm-folders {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				gap: 16px;

				.mm-folder, .mm-new-folder {
					cursor: pointer;
					width: 162px;
					display: grid;
					grid-template-columns: auto 1fr;
					column-gap: 8px;
					padding: 8px;
					align-items: center;
					border: .5px solid var(--clr-alabaster-600);
					border-radius: 4px;

					font-size: .9375em;

					.mm-folder-name {
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}

					&.mm-folder svg {
						stroke: var(--clr-buttercup-500);
						fill: var(--clr-buttercup-500);
					}

					&.mm-new-folder svg {
						stroke: var(--clr-alabaster-900);
					}

					&:hover {
						background-color: var(--clr-primary-100);
						border-color: var(--clr-primary-300);
						color: var(--clr-primary-600);

						&.mm-new-folder svg {
							stroke: var(--clr-primary-600);
						}
					}
				}
			}

			.mm-files {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				gap: 16px;

				.mm-file, .mm-new-file {
					cursor: pointer;
					height: 162px;
					width: 162px;
					border: .5px solid var(--clr-alabaster-600);
					border-radius: 4px;

					&.mm-new-file:hover, &.mm-file-active, &.mm-new-file.mm-can-drop {
						background-color: var(--clr-primary-100);
						border-color: var(--clr-primary-300);
						color: var(--clr-primary-600);
						
						svg {
							stroke: var(--clr-primary-600);
						}
					}
				}

				.mm-file {
					display: grid;
					grid-template-rows: 80% auto;

					.mm-file-preview {
						border-radius: 4px 4px 0 0;
						height: inherit;
						max-height: 100%;
						width: 100%;
						object-fit: cover;
					}

					.mm-file-name {
						padding: 5px;
						text-align: center;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}

				.mm-new-file {
					display: grid;
					grid-template-rows: 55% 45%;
					text-align: center;
					gap: 8px;

					svg {
						margin: auto auto 0 auto;
						height: 2em;
						stroke: var(--clr-alabaster-900);
					}
				}
			}
		}

		&.mm-list {
			display: grid;
			grid-template-rows: auto auto 1fr;
			padding-left: 32px;
			user-select: none;
			overflow-y: auto;

			.mm-folders, .mm-files {
				display: grid;
				grid-auto-rows: 40px;
				font-size: 14px;
				padding-right: 32px;

				.mm-folder, .mm-file {
					cursor: pointer;
					padding-left: 16px;
					padding-right: 16px;
					display: grid;
					grid-template-columns: 21px 5fr 2fr 2fr;
					column-gap: 16px;
					align-items: center;
					border-bottom: 1px solid var(--clr-alabaster-300);

					&.mm-folder:hover, &.mm-file-active {
						background-color: var(--clr-primary-100);
						color: var(--clr-primary-600);
					}

					&.mm-folder svg {
						width: 22px;
						stroke: var(--clr-buttercup-500);
						fill: var(--clr-buttercup-500);
					}

					&.mm-file {
						.mm-file-preview {
							height: 21px;
							width: 21px;
							background-color: grey;
						}
					}

					.mm-folder-author, .mm-file-author {
						text-align: end;
					}
				}

				.mm-new-folder, .mm-new-file {
					cursor: pointer;
					width: 162px;
					display: grid;
					grid-template-columns: auto 1fr;
					column-gap: 8px;
					padding: 8px;
					align-items: center;
					border: .5px solid var(--clr-alabaster-600);
					border-radius: 4px;

					// TODO: fix the button centering and its margins (waiting for once-upon-a-tam)
					margin-left: auto;
					margin-right: auto;
					margin-top: 24px;
					height: 41px;

					svg {
						stroke: var(--clr-alabaster-900);
					}
					
					&:hover, &.mm-new-file.mm-can-drop {
						background-color: var(--clr-primary-100);
						border-color: var(--clr-primary-300);
						color: var(--clr-primary-600);

						svg {
							stroke: var(--clr-primary-600);
						}
					}
				}
			}
		}
	}

	.mm-sidebar {
		grid-area: Sidebar;
		padding: 16px 24px;
		width: 384px;
		box-shadow: -1px 0 4px #00000029;

		display: grid;
		grid-template-rows: auto auto 1fr auto;
		row-gap: 16px;

		.mm-close {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;

			& > button {
				padding: 0;
				gap: 0;
				height: 24px;
				width: 24px;

				svg {
					max-width: none;
					max-height: none;
					height: 100%;
					stroke-width: 1.5px;
					stroke: var(--clr-alabaster-700);
				}

				&:hover {
					background-color: transparent;
					border-color: transparent;
					
					svg {
						stroke: var(--clr-primary-500);
					}
				}
			}
		}

		.mm-preview {
			margin: auto;
			background: gray;
			border-radius: 8px;
			width: 100%;
			height: 338px;
			object-fit: cover;

			& img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		.mm-details {
			display: grid;
			grid-template-columns: auto 1fr;
			grid-template-rows: repeat(3, auto) 1fr;
			row-gap: 8px;
			
			.mm-details-title {
				font-weight: 500;
			}

			.mm-details-content {
				text-align: right;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}

			.mm-details-name {
				cursor: pointer;
				transition: ease .2s;

				&:hover {
					color: var(--clr-primary-500);
				}
			}
		}

		.mm-actions {
			display: grid;
			grid-template-columns: auto auto;
			justify-content: end;
			column-gap: 16px;
		}
	}
}

.mm-file-input {
	display: none;
}
