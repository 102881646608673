
.color-picker-wrapper {
	position: relative;
	height: 100%;
}

.color-picker {
	z-index: 4000;
	position: absolute;
	top: 40px;
	padding: 0;
	margin: 5px;
	height: 0;
	display: none;
	border-radius: 10px;
	overflow: hidden;
	list-style: none;
	background: #ffffff;
	opacity: 0;
	box-shadow: 0px 0px 5px #afafaf;
	transition: opacity ease-in-out .4s;

	&.open {
		display: flex;
		opacity: 1;
		height: auto;
		right: 0;
	}
}