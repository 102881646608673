.dropdown {
	display: none;
	position: fixed;
	top: calc(50% + .5rem);
	right: calc(50% - .3rem);
	height: auto;
	width: max-content;
	visibility: hidden;
	opacity: 0;
	padding: .5em;
	z-index: 1001;
	background-color: #fff;
	border: solid 1px var(--clr-walkawa-gray-300);
	box-shadow: 0px 0px .5rem #00000029;
	border-radius: .25rem;
	animation: dropdown-toggle .22s ease-in-out alternate both;

	&.open {
		display: grid;
	}

	> * {
		color: var(--clr-text);
		text-align: left;
		justify-content: flex-start;
		background-color: transparent;
		cursor: pointer;
	}

	:hover {
		box-shadow: 0px 0px .5rem #00000029;
	}
}

@keyframes dropdown-toggle {
	0% {
		display: none;
		opacity: 0;
		visibility: hidden;
	}

	1% {
		display: grid;
		opacity: 0;
		visibility: hidden;
	}

	100% {
		visibility: visible;
		opacity: 1;
	}
}
