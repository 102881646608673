form {
	display: flex;
	flex-direction: column;
	gap: .5em;
	width: fit-content;
	min-width: 80%;
	max-width: 100%;
	margin: 0 auto auto auto;

	& .collapsible-body {
		display: flex;
		flex-direction: column;
		gap: .5em;
	
		& > .aside {
			display: flex;
			flex-direction: row;
			gap: .5em;
			border-top-left-radius: .25rem;
			border-top-right-radius: .25rem;
			padding: .25rem;
		}
	
		& > .subgroup {
			border-bottom-left-radius: .25rem;
			border-bottom-right-radius: .25rem;
			display: flex;
			flex-direction: column;
			gap: .5em;
			padding: .5rem;
	
			span.hint,
			span.error-message {
				display: none;
			}	
		}
	}

	& .table {
		width: 100%;
		display: flex;
		flex-direction: row;
		padding: .1rem;
		gap: 5px;

		& .header {
			padding: .25rem ;
			background-color: var(--clr-primary-500);
			color: var(--clr-alabaster-100);
			text-align: right;
			min-width: 20rem;
			border-top-left-radius: .5rem;
			border-bottom-left-radius: .5rem;
		}

		& .data {
			padding: .25rem .5rem .25rem 1rem;
			color: var(--clr-primary-500);
		}

		& .inputcell {
			flex: 1 1 auto;
		}
	}

	& .detailed-information {
		padding: 1rem 0rem .5rem 0rem;
		color: var(--clr-primary-500);
	}
}

.aside {
	display: flex;
	flex-direction: row;
	gap: .5em;
	padding: .25rem;
}

.modal form {
	min-width: 80%;
}
