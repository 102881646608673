.stat-pill { 
	background: var(--clr-alabaster-200);
	border-radius: 16px;
	display: flex;
	flex-direction: row;
	min-height: auto;
	position: relative;
	height: 1.6rem;
	padding: .2rem .5rem .2rem .5rem;

	&.action {
		background: var(--clr-primary-500);
		color: white;
		transition: background-color 0.2s ease-in-out;

		&:hover {
			background: var(--clr-primary-400);
		}

		&.open {
			background: var(--clr-primary-400);
		}
	}

	svg {
		padding: .1rem;
		stroke-width: 3px;
	}

	.container {
		position: relative;
		display: flex;
		flex-direction: row;
		height: .8rem;
		align-items: center;
		margin-bottom: auto;
		margin-top: auto;
		gap: .5rem;


		.color-dot {
			background: var(--clr-alabaster-400) 0% 0% no-repeat padding-box;
			width: 1rem;
			height: 1rem;
			border-radius: 50%;
		}
	}

}

.color-dot-alone {
	width: 1rem;
	height: 1rem;
	border-radius: 50%;
}
