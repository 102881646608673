main.authentication-page {
	display: grid;
	padding: 0 !important;
	grid-template-columns: 0 1fr;
	background-image: url('./signinBg.jpg'); // @TODO: update this with an external url once Outscale is setup.

	svg {
		width: 100%;
	} 

	& > div:last-child {
		display: grid;
		align-content: center;
		justify-items: center;
		align-items: center;
		height: 60%;
		max-width: 75%;
		min-width: 350px;
		margin-top: auto;
		margin-bottom: auto;
		background-color: white;
		box-shadow: 4px 4px 8px #00000029;
		border-radius: 8px;
		justify-self: center;

		& > *:not(a) {
			width: 70%;
			min-width: 280px;
		}

		h1 {
			margin: 0;
		}

		p {
			margin-top: 0;
		}

		& > a {
			text-align: center;
		}

		form {
			display: grid;
			width: 70%;
			min-width: 280px;
			height: fit-content;
	
			button[type="submit"] {
				width: 100%;
			}

			a {
				justify-self: flex-end;
				padding: 0;
				margin-bottom: 1em;
			}
		}
	}

	.password-definition-requirements {
		display: grid;
		padding-left: 1em;
		grid-template-columns: auto 1fr;
		align-items: center;
		gap: .1rem .5rem;
		user-select: none;

		input[type="checkbox"] {
			position: relative;
			border-radius: 50%;
			appearance: none;
			aspect-ratio: 1;
			width: 1rem;
			outline: solid 1px var(--clr-primary-500);
			border-color: transparent;
			padding: 0;

			&:checked::after {
				position: absolute;
				content: "";
				width: 5px;
				height: 8px;
				border: solid var(--clr-primary-500);
				transform: rotate(45deg);
				border-width: 0 2px 2px 0;
				top: 0px;
				left: 4px;
			}

			&[aria-invalid="true"] {
				outline: solid 1px var(--clr-cinnabar-500);
			}

			&[aria-invalid="true"]::after {
				border-color: var(--clr-cinnabar-500);
			}
		}

		input[type="checkbox"][aria-invalid="true"] + label {
			color: var(--clr-cinnabar-500);
		}
	}

	a.back-to-login {
		width: fit-content;
		margin: auto;
		margin-top: 1em;
	}
}

@media (min-width: 750px) {
	main.authentication-page {
		grid-template-columns: 40% 1fr;
		background-image: none;

		& > div:first-child {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			background-image: url('./signinBg.jpg'); // @TODO: make this asset go through an asset optimizer.
			background-clip: 'border-box';
			background-size: cover;
			background-repeat: no-repeat;
			background-position: right top;
			background-attachment: fixed;
		
			& > div {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 45%;
				height: 60%;
				background: rgba(255, 255, 255, 0.4);
				border-radius: 8px 0 0 8px;
				backdrop-filter: blur(20px);
				min-height: 440px;
				text-align: center;
		
				& > div {
					max-width: 80%;
				}
			}
		}

		& > div:last-child {
			border-radius: 0 8px 8px 0;
			justify-self: initial;
		}
	}
}
