$colors: (
	cornflower-blue: (
		100: #dee9fe,
		200: #b5c2ef,
		300: #91a4e6,
		400: #6c85de,
		500: #4767d6,
		600: #354da1,
		700: #24346b,
		800: #17243e,
		900: #121d32,
	),

	shamrock: (
		100: #DEF7ED,
		200: #BDEFDB,
		300: #9CE8CA,
		400: #7BE0B8,
		500: #5AD8A6,
		600: #44A27D,
		700: #2D6C53,
		800: #173629,
		900: #122B21,
	),

	eucalyptus: (
		100: #D4EDDE,
		200: #A8DBBD,
		300: #7DCA9D,
		400: #51B87C,
		500: #26A65B,
		600: #1C7D44,
		700: #13532E,
		800: #0A2917,
		900: #082112,
	),

	walkawa-gray: (
		100: #DFE2E9,
		200: #BEC6D3,
		300: #9EA9BE,
		400: #7D8DA8,
		500: #5D7092,
		600: #46546E,
		700: #2F3849,
		800: #171C25,
		900: #13161D,
	),

	cinnabar: (
		100: #F9CCCC,
		200: #F39999,
		300: #EC6666,
		400: #E63333,
		500: #E00000,
		600: #A80000,
		700: #700000,
		800: #380000,
		900: #2D0000,
	),

	wisteria: (
		100: #EADFF2,
		200: #D5C0E4,
		300: #C0A0D7,
		400: #AB81C9,
		500: #9661BC,
		600: #71498D,
		700: #4B315E,
		800: #26182F,
		900: #1E1326,
	),

	buttercup: (
		100: #FDF2D0,
		200: #FBE5A2,
		300: #FAD773,
		400: #F8CA45,
		500: #F6BD16,
		600: #B98E10,
		700: #7B5F0B,
		800: #3E2F06,
		900: #312604,
	),

	burnt-sienna: (
		100: #FBE2DC,
		200: #F7C5B9,
		300: #F2A897,
		400: #EE8B74,
		500: #EA6E51,
		600: #B0533D,
		700: #753729,
		800: #3A1C14,
		900: #2F1610,
	),

	carnation-pink: (
		100: #FFEBF3,
		200: #FFD6E7,
		300: #FFC2DB,
		400: #FFADCF,
		500: #FF99C3,
		600: #BF7392,
		700: #804D62,
		800: #402631,
		900: #331F27,
	),

	alabaster: (
		100: #FAFAFA,
		200: #E5E8EA,
		300: #D1D5DA,
		400: #BCC3C9,
		500: #A8B0B9,
		600: #939EA9,
		700: #7F8B99,
		800: #6B7988,
		900: #566678,
	),
);

$mine-shaft: hsl(0, 0%, 17%);
$primary: map-get($colors, cornflower-blue);
$text: $mine-shaft;

:root {
	--clr-mine-shaft: #{$mine-shaft};
	--clr-text: #{$text};

	/// Generates scss props for each color tone like "--clr-cornflower-blue-500: #5B8FF9"
	@each $color, $variants in $colors {
		@each $tone, $value in $variants {
			@if $color == 'cornflower-blue' {
				--clr-primary-#{$tone}: #{$value};				
			}
			--clr-#{$color}-#{$tone}: #{$value};
		}
	}
}
