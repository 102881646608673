@use 'styles/abstracts/_sizes' as sizes;

ul.field-array {
	list-style: none;
	padding: 0;
	margin: 0;
	margin-bottom: 1.5em;

	li {
		display: flex;
		flex-direction: row;
		gap: 1em;
		align-items: flex-start;	

		& > *:first-child {
			flex: 1;
		}

		& > *:last-child:not(:only-child) {
			flex: 0;
		}

		.input-wrapper {
			margin: 0;
		}

		button {
			// We set a top margin equal to the size of the label + the gap between the label and input
			// This is done to align vertically the input and button.
			margin-top: calc((sizes.$line-height * sizes.$caption-font-size) + .25rem);
		}
	}

	.field-array-append {
		width: 100%;
		margin-top: 1em;
	}
}
