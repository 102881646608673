@import 'styles/base/_mixins.scss';

.partner-summary {
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: repeat(2, auto);
	gap: 0;

	h3 {
		align-self: center;
		font-size: 1rem;
		font-weight: 600;
		margin: 0;
	}

	.edit-button {
		margin-left: .5rem;
		width: fit-content;
		min-height: 2rem;
		min-width: 2rem;
		padding: 0;
	}
	
	& > div {
		grid-column: 1 / 3;
		display: flex;
		gap: 1rem;
		align-items: center;
	
		color: var(--clr-walkawa-gray-500);
	
		a, span {
			display: flex;
			align-items: center;
			gap: .25rem;
		}
	
		svg {
			width: 1rem;
			stroke: var(--clr-walkawa-gray-500);
		}
	}

	&--skeleton {
		@extend .partner-summary;

		& > h3 {
			grid-column: 1 / 3;
			height: 1.728rem; // matches h3 font-size
			width: 100%;
		}

		a, span {
			height: 1.32rem; // matches the span's line-height
			width: 100%;

			& > span {
				height: 1rem; // matches the span's font-size
				width: 100%;
			}
		}

		h3,
		*:is(a, span) > span {
			border-radius: calc(1rem / 2);
			@include skeleton-ui;
		}
	}
}

@include skeleton-ui-animation;
