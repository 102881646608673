table.grid {
	// Inputs inside an expense table should not have a label displayed.
	label,
	span.hint,
	span.error-message {
		display: none;
	}
	border-collapse: separate;
	border-spacing: 0;

	thead {
		tr.single {
			th {
				background-color: var(--clr-primary-100);
				font-weight: bold;
				padding: .5rem;
		
				.cell-left {
					text-align: left;
				}
		
				.cell-right {
					text-align: right;
				}

				&:first-child { border-top-left-radius: .25rem; }
				&:last-child { border-top-right-radius: .25rem; }
			}	
		}
	}

	tbody {
		tr {
			td {
				white-space: nowrap;
				padding: .25rem;
			}
		
			td.cell-total {
				background-color: #F1F1F5;
				border-left: 1px solid var(--clr-primary-200);
				font-weight: bold;
				text-align: right;
			}
		
			td.row-actions {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-around;
			}
		}
	}
}

table.total-overview {
	border-collapse: separate;
	border-spacing: 0;
	margin-left: auto;
	margin-right: auto;

	tr {
		th {
			background-color: var(--clr-primary-500);
			color: var(--clr-alabaster-100);
			font-weight: bold;
			padding: .5rem;
			text-align: center;

			&:first-child { border-top-left-radius: .25rem; }
			&:last-child { border-top-right-radius: .25rem; }
		}
	
		td {
			background-color: var(--clr-primary-100);
			font-weight: bold;
			padding: .5rem;
			text-align: center;
			white-space: nowrap;

			&:first-child { border-bottom-left-radius: .25rem; }
			&:last-child { border-bottom-right-radius: .25rem; }
		}
	}
}