.target-header {
	display: flex;
	flex-direction: row;
	margin-bottom: 0;

	.return-button {
		margin-top: auto;
		margin-bottom: auto;
	}

	.identity {
		display: flex;
		flex-direction: column;
		margin-bottom: 1rem;

		h1, h2, h3, h4 {
			margin: 0px;
			color: #3d3d3d;
		}

		h5 {
			margin: 0px;
			color: #3d3d3d;
			font-size: 16px;
		}

		p {
			margin-top: 5px;
			margin-bottom: 0px;
			color: #878F9A;
		}
	}
	.metadata {
		display: flex;
		flex-direction: column;
		margin-left: auto;
		color: #878F9A;
		min-width: 14rem;
		width: 50%;

		h1, h2, h3, h4, h5, h6 {
			margin: 0;
			text-align: right;
		}

		p {
			margin: 0;
			font-size: .8rem;
			text-align: right;
		}

		.statistics {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-end;

			gap: .5rem;
			margin-top: .5rem;
			font-size: .8rem;
			color: #3d3d3d;

			align-self: flex-end;
			align-content: flex-end;
		}

	}
}
