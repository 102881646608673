@import 'styles/base/_mixins.scss';

.status-tag {	
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: .85rem;
	gap: .5rem;
	padding: .25rem .5rem .25rem .5rem;
	width: fit-content;
	border-radius: 1rem;
	background-color: var(--clr-alabaster-200);

	&--skeleton {
		@extend .status-tag;
		height: 1.5rem;
		width: 4.5rem;

		@include skeleton-ui;
	}
}

.status-dot-color {
	content:"\A";
	width: .75rem;
	height: .75rem;
	border-radius: 50%;
	display: inline-block;
}

@include skeleton-ui-animation;
