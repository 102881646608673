@import 'styles/base/_mixins.scss';

.history-list-item {
	list-style: none;
	display: grid;
	grid-template-columns: auto 1fr;
	gap: .5rem;
	align-items: flex-end;

	& > img,
	& > [role="img"] {
		border-radius: 50%;
		width: 2rem;
		aspect-ratio: 1;
	}

	& > .history-list-item-content {
		position: relative;
		display: flex;
		flex-direction: column;
		gap: .5rem;
		padding: .5rem;
		border-radius: .25rem;
		box-shadow: 0 0 .25rem rgba(0, 0, 0, .24);
		transition:
			box-shadow .2s ease-in-out,
			color .2s ease-in-out,
			border-color .2s ease-in-out,
			background-color .2s ease-in-out;
		border: solid 1px transparent;
		background-color: white;
		cursor: default;

		&:hover {
			box-shadow: 0 0 .25rem rgba(0, 0, 0, .32);
		}

		p {
			overflow-wrap: anywhere;
		}

		& > div {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: center;
			gap: .5rem;

			* {
				font-size: .75rem;
			}

			Button {
				cursor: pointer;
				box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
				width: 1.8rem !important;
				height: 1.8rem;
				min-height: 1rem;
				min-width: 1rem;
				margin-left: 0 !important;
			}

			span {
				position: relative;
				display: inline-flex;
				gap: .25rem;
				align-items: center;
				justify-content: center;
				padding: .25rem .5rem;
				height: 1.5rem;
				border-radius: 1rem;
				background-color: var(--clr-alabaster-200);

				img,
				[role="img"],
				svg {
					height: 1rem;
					border-radius: 50%;
					aspect-ratio: 1;
				}

				&.tag-with-icon {
					svg {
						border-radius: 0;
					}
				}
			}

			time {
				margin-left: auto;
				text-align: end;
			}
		}
	}

	&--skeleton {
		@extend .history-list-item;
		width: 100%;

		& > [role="button"] {
			&:hover {
				background-color: white;
				box-shadow: 0 0 .25rem rgba(0, 0, 0, .24);
				border-color: transparent;
				color: var(--clr-text);
				cursor: default;
			}

			p {
				height: 1.32rem;
				width: 100%;
				border-radius: calc(1.32rem / 4);
			}

			& > div {
				span, time {
					margin: .25rem 0;
					height: 1rem;
					width: 3rem;
					border-radius: calc(1rem / 2);
				}

				time {
					width: 4rem;
				}		
			}
		}

		p, span, time, img, [role="img"] {
			@include skeleton-ui;
		}
	}
}

@include skeleton-ui-animation;
