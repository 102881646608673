.filter-panel {
	position: absolute;
	top: 0;
	right: 0;
	width: 45vw;
	height: fit-content;
	min-height: 100%;
	background-color: white;
	z-index: 999;

	display: grid;
	grid-template-columns: 1fr auto;
	grid-template-rows: auto 1fr;
	align-items: flex-start;

	padding: 2rem;
	transition: width 0.3s ease-in-out, padding 0.3s ease-in-out;


	& > button {
		align-self: center;
	}

	form {
		grid-column: 1 / 3;
		margin: 0;
		width: 100%;
	}

	& > ul {
		grid-column: 1 / 3;
	}

	&[aria-hidden="true"] {
		width: 0;
		padding-left: 0;
		padding-right: 0;

		* {
			display: none;
		}
	}
 
	.filter-form-fielset {
		position: relative;
		display: grid;
		grid-template-columns: minmax(4rem, .3fr) repeat(3, 1fr) auto;
		gap: .5rem;
		align-items: center;
		border: none;

		p {
			margin: 0;
		}

		& > * {
			height: fit-content;
			
			&:not(.select-input-group) {
				padding: .25rem .5rem;
				min-height: 0;
				min-width: 0;
				width: 100%;
			}
		}

		.select-input-group {
			position: relative;

			&, & * {
				cursor: pointer;
			}

			select {
				background-color: white;
				border: solid 1px var(--clr-alabaster-400);
				border-radius: .25rem;
				padding: .5rem;
				padding-right: 2rem;
				appearance: none;
				width: 100%;
				transition: all 0.1s ease-in;

				option {
					overflow-wrap: break-word;
					white-space: normal;
				}
			}

			svg {
				position: absolute;
				inset: 0 .5rem 0 auto;
				margin: auto;
				width: 1rem;
				aspect-ratio: 1;

				pointer-events: none;
				transition: all 0.1s ease-in;
			}

			&:hover {
				select {
					border-color: var(--clr-primary-500);
					color: var(--clr-primary-500);
				}

				svg {
					stroke: var(--clr-primary-500);	
				}
			}

			&:focus, &:focus-visible, &:focus-within {
				select {
					outline: solid 1px var(--clr-primary-500);
					outline-offset: 2px;
				}
			}
		}

		& > button {
			position: relative;

		}

		ul[role="menu"] {
			position: absolute;
			list-style: none;
			background-color: white;
			border: solid 1px var(--clr-alabaster-400);
			border-radius: .25rem;
			padding: .25rem;
			top: 2rem;
			right: 1.5rem;
			width: fit-content;
			z-index: 1000;

			&[aria-hidden="true"] {
				display: none;
			}

			button {
				border: none;
				gap: .75rem;
			}
		}
	}
}
