@use 'styles/base/_typography' as typography;

.collapsible {
	width: 100%;
	border: solid 1px var(--clr-walkawa-gray-500);
	border-radius: .5em;
	
	.collapsible-toggle {
		width: 100%;
		font-size: 1rem;
		font-weight: 500;
		display: flex;
		padding: .5em 1em;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		border-radius: 0;
		border: 0;
		color: var(--clr-text);
		cursor: pointer;
		background-color: transparent;
		border-radius: .5em;
		transition: color cubic-bezier(0.4, 0, 0.5, 0.9) .32s;

		& > svg {
			transition: transform cubic-bezier(0.4, 0, 0.5, 0.9) .32s;
		}

		&:hover {
			color: var(--clr-primary-500);

			& > svg {
				stroke: var(--clr-primary-500);
			}
		}
	}
	
	.collapsible-body {
		display: none;
		padding: 1em;
	}

	.collapsible-toggle[aria-expanded="true"] {
		border-radius: .5em .5em 0 0;

		svg {
			transform: rotate(180deg);
		}
	}
	.collapsible-toggle[aria-expanded="true"] + .collapsible-body {
		display: block;
	}
}

form .collapsible .collapsible-toggle[aria-expanded="true"] + .collapsible-body {
	display: flex;
}
