@mixin skeleton-ui {
	position: relative;
	overflow: hidden;
	background: #DDDBDD;

	&::after {
		content: '';
		position: absolute;
		inset: 0;
		transform: translateX(-100%);
		background-image: linear-gradient(
			90deg,
			rgba(#fff, 0) 0,
			rgba(#fff, 0.2) 20%,
			rgba(#fff, 0.5) 60%,
			rgba(#fff, 0)
		);
		animation: shimmer 2s infinite;
	}
}

@mixin skeleton-ui-animation {
	@keyframes shimmer {
		100% {
			transform: translateX(100%);
		}
	}
}
