@charset 'utf-8';
@use './pages' as *;
@use './base' as *;
@use './abstracts/colors' as colors;

@use './layout' as *;
@use './components' as *;

* {
	font-family: 'IBM Plex Sans', 'Helvetica Neue', sans-serif;
	box-sizing: border-box;
}

#root {
	display: grid;
	grid-template-areas: 'Header Header' 'Navbar Main';
	grid-template-rows: auto 1fr;
	grid-template-columns: auto 1fr;
	height: 100%;
	width: 100%;
	overflow: auto;
	
	& > header {
		grid-area: Header;
	}
	
	& > nav {
		grid-area: Navbar;
	}
	
	& > main {
		display: grid;
		grid-area: Main;
		padding: 32px;
		overflow: auto;
		background-color: var(--clr-alabaster-100);
	}
}


svg {
	width: auto;
}

.pace .pace-progress {
	height: 4px;
}
