@use 'styles/abstracts/_sizes' as sizes;

%editor-section {
	background-color: #fff;
	border-radius: .5em;
	box-shadow: 0px 0px .25em #00000029;
	padding: 1em;
}

@mixin gridcontent(
	$gap: null,
	$columnGap: .5em,
	$rowGap: .5em,
	$templateAreas: null,
	$templateColumns: null,
	$templateRows: null,
	$autoRows: null,
	$autoColumns: null,
	$autoFlow: null,
	) {
	display: -ms-grid; // Compatibility for MS Edge
	display: grid;

	@if ($gap) {
		gap: $gap;
	} @else {
		grid-column-gap: $columnGap;
		grid-row-gap: $rowGap;
	}

	@if ($templateAreas) {
		grid-template-areas: $templateAreas;
	}
	@if ($templateColumns) {
		grid-template-columns: $templateColumns;
	}
	@if ($templateRows) {
		grid-template-rows: $templateRows;
	}
	@if ($autoRows) {
		grid-auto-rows: $autoRows;
	}
	@if ($autoColumns) {
		grid-auto-columns: $autoColumns;
	}
	@if ($autoFlow) {
		grid-auto-flow: $autoFlow;
	}
}

.format-content {
	@extend %editor-section;

	div[role="tablist"] {
		@include gridcontent($columnGap: .5em, $autoFlow: column);
		margin-bottom: 1em;
		width: 100%;
	}
}