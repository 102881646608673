.table-page {
	display: grid;
	grid-template-rows: auto 1fr auto;
	height: 100%;
	width: 100%;
	overflow: auto;

	.header {
		display: flex;
		flex-direction: row;
		color: #878F9A;
		min-width: 14rem;
		width: 50%;
		margin-left: auto;

		.statistics {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			
			width: 100%;
			gap: .5rem;
			margin-top: .5rem;
			font-size: .8rem;
			color: #3d3d3d;
			
			justify-content: flex-end;
			align-self: flex-end;
			align-content: flex-end;
		}
	}
}
