@use 'styles/abstracts/_colors' as colors;
@use 'styles/abstracts/sizes' as sizes;

.data-table-global-actions {
	display: flex;
	flex-direction: row;
	gap: 1em;
	align-items: center;
	margin-bottom: 1em; //@TODO: set this as a gap in the main grid once we've implemented a way to style main differently depending on the page type (data list, form...)
}

div[role="table"].data-list,
div[role="grid"].data-list {
	position: relative;
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-rows: auto 1fr auto;
	overflow: auto;

	& *[role="columnheader"],
	& *[role="cell"] {
		padding-left: .5em;
		padding-right: .5em;

		& > span {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	& *[role="columnheader"][data-sticky-td="true"],
	& *[role="cell"][data-sticky-td="true"] {
		background-color: white;
	}

	& *[role="columnheader"][data-sticky-last-left-td="true"]:not(.header-selection-cell),
	& *[role="cell"][data-sticky-last-left-td="true"]:not(.row-selection-cell) {
		border-right: solid 1px var(--clr-walkawa-gray-100);
	}

	[role="rowgroup"].header-row {
		position: sticky;
		width: 100%;
		background-color: white;
		top: 0;
		z-index: 998;
		border-radius: 4px;
		box-shadow: 0 .1em .5em rgba(map-get(map-get(colors.$colors, alabaster), 500), 0.3);
		
		& [role="row"] {
			background-color: white;
		}

		& *[role="columnheader"] {
			position: relative;
			background-color: white;

			span {
				position: relative;
				display: inline-block;
				height: 100%;
				width: 100%;
				padding-top: 1em;
				padding-bottom: 1em;
				font-weight: 600;

				&.sortable {
					cursor: pointer;
				}
			}

			&[aria-sort="ascending"] span,
			&[aria-sort="descending"] span {
				color: var(--clr-primary-500);

				&::after {
					height: fit-content;
					margin-left: .75rem;
					color: var(--clr-primary-500);
				}
			}

			&.header-selection-cell, 
			&.header-selection-cell span {
				display: flex !important;
				justify-content: center;
				align-items: center;
			}


			&[aria-sort="ascending"] span::after {
				content: "▲";
			}
	
			&[aria-sort="descending"] span::after {
				content: "▼";
			}

			.column-resizer {
				position: absolute;
				height: 100%;
				width: 1em;
				right: 0;
				top: 0;
				touch-action: none;
				cursor: col-resize;
			}
	
			&:hover .column-resizer {
				background-color: var(--clr-primary-500);
				color: var(--clr-alabaster-100);
				background-image: repeating-linear-gradient(45deg, currentColor 0, currentColor 1px, transparent 0, transparent 50%);
				background-size: .4em .4em;
			}
	
			&.header-selection-cell {
				justify-content: center;
				input {
					margin: 0;
				}

				.column-resizer {
					display: none;
				}
			}
		}
	}

	*[role="rowgroup"]:not(.header-row) {
		width: 100%;
		position: relative;
		height: fit-content;

		*[role="row"] {
			position: relative;
			width: 100%;
			background-color: white;

			&.selected {
				background-color: var(--clr-primary-100);

				button.icon-only:not(:disabled):hover {
					background-color: var(--clr-primary-100);
					border-color: var(--clr-primary-500);
				}
			}

			&::after {
				content: '';
				position: absolute;
				width: auto;
				inset: auto .5em 0 .5em;
				border-bottom: solid 1px var(--clr-walkawa-gray-100);
			}


		}
		
		[role="cell"] {
			display: flex;
			align-items: center;
			padding: .5em .25em .5em .5em;
			font-size: sizes.$table-font-size;

			&.row-selection-cell {
				justify-content: center;
			}
		}
	}
}

.data-list-footer {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: fit-content;
	background-color: white;
	padding: .5em 1em;
	gap: .5em;

	.react-select {
		cursor: pointer;
		height: auto;
		min-height: auto;

		& > [class$="control"] {
			min-height: auto;
			cursor: pointer;
		}
	}

	.data-list-navigation {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: .1em;

		button {
			border-radius: .25rem;
			aspect-ratio: 1;
			height: fit-content;
			min-height: auto;
		}
	}

	.data-list-selected-lines-indicator {
		flex: 1;
		text-align: end;
	}
}
